const visitorsReducer = (state = null, action) => {
  switch (action.type) {
    case "SETGUESTS":
      return (state = action.data);
    case "REMOVEGUESTS":
      return (state = null);
    default:
      return state;
  }
};

export default visitorsReducer;
