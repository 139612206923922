import React, { Component } from "react";
import ShimmerLoader from "../Shimmer/shimmer";

class Payments extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return <ShimmerLoader message="Kindly try again later!"/>;
  }
}

export default Payments;
