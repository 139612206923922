import * as firebase from "firebase/app";
import "firebase/messaging";
let config = {
  appId: "1:966295285008:android:ab8315cecdfefb7916c995",
  apiKey: "AIzaSyDUBWT-7QshZ1FAkrsJwNYm0U1SzCPas4o",
  //   authDomain: "XXXXX",
  //   databaseURL: "XXXXX",
  projectId: "residence-app-8e3b3",
  //   storageBucket: "XXXX",
  messagingSenderId: "966295285008"
};

const initializedFirebaseApp = firebase.initializeApp(config);
const messaging = initializedFirebaseApp.messaging();
messaging.usePublicVapidKey(
  // Project Settings => Cloud Messaging => Web Push certificates
  "BDZOkAF3gLgSk9gVCEA1VA7uJJPYYYqNMaU0FQGOdfO59o-Sj83B_Lzz6VRQ6LWV93J5oPDvXP8Ktgu8VqM3cmA"
);
export { messaging };
