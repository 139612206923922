export const Login = data => {
  return {
    type: "LOGIN"
  };
};

export const Logout = data => {
  return {
    type: "LOGOUT"
  };
};

export const Regulate = data => {
  return {
    type: "REGULATE",
    data
  };
};

export const setComplaints = data => {
  return {
    type: "SETCOMPLAINTS",
    data
  };
};

export const removeComplaints = data => {
  return {
    type: "REMOVECOMPLAINTS",
    data
  };
};

export const setEvents = data => {
  return {
    type: "SETEVENTS",
    data
  };
};

export const removeEvents = data => {
  return {
    type: "REMOVEEVENTS",
    data
  };
};

export const SetFacilities = data => {
  return {
    type: "SETFACILITIES",
    data
  };
};

export const RemoveFacilities = data => {
  return {
    type: "REMOVEFACILITIES",
    data
  };
};

export const UnRegulate = data => {
  return {
    type: "UNREGULATE",
    data
  };
};

export const setData = data => {
  return {
    type: "ADD",
    data
  };
};

export const removeData = data => {
  return {
    type: "REMOVE",
    data
  };
};

export const chooseCompany = data => {
  return {
    type: "SUBSCRIBE",
    data
  };
};

export const deleteCompany = data => {
  return {
    type: "UNSUBSCRIBE",
    data
  };
};

export const servicesSetter = data => {
  return {
    type: "SETSERVICES",
    data
  };
};

export const residentsSetter = data => {
  return {
    type: "SETRESIDENTS",
    data
  };
};
export const residentsRemover = data => {
  return {
    type: "REMOVERESIDENTS",
    data
  };
};

export const visitorsSetter = data => {
  return {
    type: "SETGUESTS",
    data
  };
};

export const visitorsRemover = data => {
  return {
    type: "REMOVEGUESTS",
    data
  };
};

export const servicesRemover = data => {
  return {
    type: "REMOVESERVICES",
    data
  };
};

export const setBranches = data => {
  return {
    type: "SETBRANCHES",
    data
  };
};

export const setTransactions = data => {
  return {
    type: "SETTRANSACTIONS",
    data
  };
};

export const removeTransactions = data => {
  return {
    type: "REMOVETRANSACTIONS",
    data
  };
};
