import React, { useState } from "react";
import ErrorBoundary from "../Errors/ErrorBoundary";
import ModalDialog from "../Modals/modal";
import Swal from "sweetalert2";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { chooseCompany, setBranches } from "../../actions";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import $ from "jquery";
import * as ROUTES from "../constants";
const BranchModal = () => {
  const dir = useSelector(state => state.chooserReducer);
  const [name, setBranchName] = useState("");
  const [description, setBranchDescription] = useState("");

  const [disabled, setDisabled] = useState(false);
  const [spinHidden, setSpinHidden] = useState(true);
  const [spin, setSpin] = useState(true);

  const dispatch = useDispatch();

  const clickRef = React.createRef();

  function clearFields() {
    setBranchName("");
    setBranchDescription("");
    setSpinHidden(true);
    setSpin(false);
    setDisabled(false);
    Swal.fire("", "Branch added successfully!", "success");
    $("#branchModal").modal("hide");
  }

  async function finaliseBranch() {
    const data = {
      action: "addBranch",
      name,
      description,
      token: dir.token,
      subscriptionId: dir.subscriptionId
    };
    await axios
      .post(ROUTES.GLOBAL_URL, data)
      .then(response => {
        if (response.data.status) {
          clearFields();
          dispatch(setBranches(response.data.branches));
        } else {
          alert(response.data.message);
          setSpinHidden(true);
          setSpin(false);
          setDisabled(false);
        }
      })
      .catch(() => {});
  }
  function addBranch(e) {
    e.preventDefault();

    let error = "";
    if (name.length < 3) {
      Swal.fire("", "Invalid branch name!", "warning");
    } else if (description.length < 4) {
      Swal.fire("", "Invalid description");
    } else {
      setSpinHidden(false);
      setSpin(true);
      setDisabled(true);
      finaliseBranch();
    }
  }

  return (
    <ErrorBoundary>
      <ModalDialog>
        <div className="modal" id="branchModal">
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h3 className="modal-title">Add a new branch</h3>
                {/* <button type="button" className="close" data-dismiss="modal">
                &times;
              </button> */}
              </div>
              <div className="modal-body">
                <form onSubmit={e => addBranch(e)} className="residentForm">
                  <div className="form-group">
                    <input
                      type="text"
                      value={name}
                      required
                      onChange={e => setBranchName(e.target.value)}
                      className="form-control"
                      placeholder="Enter branch name (required)"
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      value={description}
                      required
                      onChange={e => setBranchDescription(e.target.value)}
                      className="form-control"
                      placeholder="Enter branch description (required)"
                    />
                  </div>

                  <div className="float-right mr-auto">
                    <button
                      type="submit"
                      disabled={disabled}
                      className="btn btn-outline-primary mr-2"
                    >
                      <FontAwesomeIcon
                        className="mr-1"
                        style={{
                          color: "#5C9BD1",
                          fontSize: 20
                        }}
                        spin={spin}
                        hidden={spinHidden}
                        icon={faSpinner}
                      />
                      Submit
                    </button>

                    <button
                      type="submit"
                      ref={clickRef}
                      data-dismiss="modal"
                      className="btn btn-outline-danger"
                    >
                      Close
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </ModalDialog>
    </ErrorBoundary>
  );
};

export default BranchModal;
