import React, { useState, useEffect } from "react";
import ModalDialog from "../Modals/modal";
import Swal from "sweetalert2";
import axios from "axios";
import { useDispatch } from "react-redux";
import { residentsSetter } from "../../actions";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Search from "../Search/search";
import * as ROUTES from "../constants";
import UIfx from "uifx";
import mp3File from "../../assets/point.mp3";

const ResidentRegistration = props => {
  const dataState = props.dataState;
  const [roleId, setRoleId] = useState("");
  const [name, setName] = useState("");
  const [passport, setPassport] = useState("");
  const [mobile, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [location, setLocation] = useState("");
  const [about, setAbout] = useState("");
  const [hiddenState, setHidden] = useState(true);
  const [branchId, setBranch] = useState("");
  const beep = new UIfx(mp3File, {
    volume: 1, // number between 0.0 ~ 1.0
    throttleMs: 100
  });
  const [disabled, setDisabled] = useState(false);
  const [spinHidden, setSpinHidden] = useState(true);
  const [spin, setSpin] = useState(true);

  const [header, setHeader] = useState("- Resident Registration");
  const dispatch = useDispatch();

  const clickRef = React.createRef();

  const branchRef = React.createRef();

  function roleChange(e) {
    let roleId = e.target.value;

    if (roleId === "4") {
      setHeader(`${name} - Company Registration`);
      setHidden(false);
    } else {
      setHeader(`- Resident Registration`);
      setHidden(true);
    }

    setRoleId(e.target.value);
  }

  function registerResident(e) {
    e.preventDefault();
    if (branchId === "") {
      beep.play();
      alert("Please assign a branch to continue!");
      return;
    }
    if (roleId === "0") {
      setRoleId("2");
    }
    if (!hiddenState) {
      if (email.length < 7) {
        beep.play();
        Swal.fire({
          type: "error",
          text: "A valid email address is required for company creation!",
          showConfirmButton: false,
          timer: 1500
        });
      }
    }
    let error = "";
    if (name.length < 3) {
      alert("Invalid resident name!");
    } else if (mobile.length < 9) {
      alert("Invalid mobile or telephone number!");
    } else if (passport.length < 6) {
      alert("Invalid Passport or National Identity number!");
    }

    const data = {
      action: "registerMember",
      username: name,
      password: "",
      telephone: mobile,
      cardNo: passport,
      location,
      email,
      roleId,
      about,
      branchId,
      subscriptionId: dataState.subscriptionId,
      token: dataState.token
    };

    Swal.fire({
      text: "Proceed to add resident?",
      showLoaderOnConfirm: true,
      showCancelButton: true,
      preConfirm: async () => {
        await axios
          .post(ROUTES.RESIDENTS_URL, data)
          .then(response => {
            beep.play();
            if (response.data.status) {
              clickRef.current.click();
              dispatch(residentsSetter(response.data.residents));
              alert(
                "Resident added successfully,an sms has been sent informing them of the same!"
              );
              clearFields();
            } else {
              alert(response.data.message);
            }
          })
          .catch(e => {
            //console.log(e);

            Swal.fire("", "No internet connection", "warning");
          });
      }
    });
  }

  function clearFields() {
    setRoleId("");
    setAbout("");
    setName("");
    setEmail("");
    setBranch("");
    setPhone("");
    setLocation("");
    setPassport("");
    setHidden(true);
  }

  function setPerson(data) {
    setName(data.person.name);
    setPassport(data.person.passport);
    setPhone(data.person.phone);
  }

  return (
    <ModalDialog>
      <div className="modal" id="residentModal">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h3 className="modal-title">{header}</h3>
              {/* <button type="button" className="close" data-dismiss="modal">
                &times;
              </button> */}
            </div>
            <div className="modal-body">
              <Search
                clearFields={e => clearFields(e)}
                setPerson={data => setPerson(data)}
              />
              <form
                onSubmit={e => registerResident(e)}
                className="residentForm"
              >
                <div className="form-group">
                  <input
                    type="text"
                    value={name}
                    required
                    onChange={e => setName(e.target.value)}
                    className="form-control"
                    placeholder="Enter residents name (required)"
                  />
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    value={mobile}
                    required
                    onChange={e => setPhone(e.target.value)}
                    className="form-control"
                    placeholder="Enter residents mobile (required)"
                  />
                </div>

                <div className="form-group">
                  <input
                    type="text"
                    required
                    value={passport}
                    onChange={e => setPassport(e.target.value)}
                    className="form-control"
                    placeholder="Enter residents ID/Passport number (required)"
                  />
                </div>

                <div className="form-group">
                  <input
                    type="text"
                    value={about}
                    required
                    onChange={e => setAbout(e.target.value)}
                    className="form-control"
                    placeholder="Enter residents about info"
                  />
                </div>

                <div className="form-group">
                  <input
                    type="text"
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                    className="form-control"
                    placeholder="Enter residents email address (required for companies)"
                  />
                </div>

                <div className="form-group">
                  <select
                    value={branchId}
                    ref={branchRef}
                    onChange={e => setBranch(e.target.value)}
                    onLoad={e => setBranch(e.target.value)}
                    className="form-control"
                    required
                  >
                    <option value="">Select a branch to proceed</option>
                    {dataState.branches.map(opt => {
                      return (
                        <option value={opt.branchId} key={opt.branchId}>
                          {opt.branchName}
                        </option>
                      );
                    })}
                  </select>
                </div>

                <div className="form-group">
                  <select
                    value={roleId}
                    onChange={e => roleChange(e)}
                    className="form-control"
                  >
                    {dataState.roles.map(opt => {
                      return (
                        <option value={opt.roleId} key={opt.roleId}>
                          {opt.name}
                        </option>
                      );
                    })}
                  </select>
                </div>

                <div hidden={hiddenState} className="form-group">
                  <input
                    type="text"
                    value={location}
                    onChange={e => setLocation(e.target.value)}
                    className="form-control"
                    placeholder="Enter companies location"
                  />
                </div>
                <button
                  onClick={e => clearFields(e)}
                  className="btn btn-outline-info"
                >
                  Clear
                </button>
                <div className="float-right mr-auto">
                  <button
                    className="btn btn-outline-primary mr-2"
                    disabled={disabled}
                  >
                    <FontAwesomeIcon
                      className="mr-1"
                      style={{
                        color: "#5C9BD1",
                        fontSize: 20
                      }}
                      spin={spin}
                      hidden={spinHidden}
                      icon={faSpinner}
                    />
                    Submit
                  </button>

                  <button
                    ref={clickRef}
                    type="submit"
                    data-dismiss="modal"
                    className="btn btn-outline-danger"
                  >
                    Close
                  </button>
                </div>
              </form>
            </div>

            <div className="modal-footer pull-right  float-right">
              {/* <div className="float-right mr-auto">
                <button type="submit" className="btn btn-outline-primary mr-2">
                  Submit
                </button>

                <button
                  type="submit"
                  data-dismiss="modal"
                  className="btn btn-outline-danger"
                >
                  Close
                </button>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </ModalDialog>
  );
};

export default ResidentRegistration;
