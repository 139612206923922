import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Card, CardTitle, CardFooter } from "reactstrap";
import { useHistory } from "react-router-dom";
import * as ROUTES from "../constants";
import PagingItems from "../Guests/pagingItems";
import axios from "axios";
import Swal from "sweetalert2";
import { chooseCompany, deleteCompany, setBranches } from "../../actions";

import { store } from "react-notifications-component";
import UIfx from "uifx";
import mp3File from "../../assets/point.mp3";

const Branches = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const dir = useSelector(state => state.chooserReducer);
  const branches = useSelector(state => state.branchesReducer);
  const [pageto, setPageTo] = useState(6);
  const [pageFrom, setPageFrom] = useState(0);
  const beep = new UIfx(mp3File, {
    volume: 1.0, // number between 0.0 ~ 1.0
    throttleMs: 100
  });

  function setPagingNext() {
    setPageTo(pageto + 6);
    setPageFrom(pageFrom + 6);
  }

  function setPagingPrev() {
    setPageTo(pageto - 6);
    setPageFrom(pageFrom - 6);
  }

  function changeBranchStatus(status, branchId) {
    Swal.fire("", "Proceed to change branch status?", "warning");
    Swal.fire({
      type: "warning",
      text: "Proceed to change branch status?",
      showCancelButton: true,
      cancelButtonClass: "btn btn-sm btn-danger",
      confirmButtonClass: "btn btn-sm btn-info",
      cancelButtonColor: "#ff0000",
      cancelButtonText: "No, cancel please!",
      confirmButtonText: "Proceed",
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        const data = {
          action: "changeBranchStatus",
          branchId,
          status,
          subscriptionId: dir.subscriptionId,
          token: dir.token
        };
        await axios
          .post(ROUTES.GLOBAL_URL, data)
          .then(response => {
            beep.play();
            if (response.status === 200) {
              const notification = {
                title: "Success",
                message: response.data.message,
                type: "default",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                  duration: 3000,
                  onScreen: true
                }
              };
              store.addNotification(notification);

              if (dir.profile.branchId === branchId) {
                dispatch(deleteCompany(null));
                history.push("/");
              }
              dispatch(setBranches(response.data.branches));
            } else {
              const notification = {
                title: "Error",
                message: "Something went wrong!",
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                  duration: 3000,
                  onScreen: true
                }
              };
              store.addNotification(notification);
            }
          })
          .catch(() => {
            const notification = {
              title: "Error",
              message: "Internet connectivity lost!",
              type: "danger",
              insert: "top",
              container: "top-right",
              animationIn: ["animated", "fadeIn"],
              animationOut: ["animated", "fadeOut"],
              dismiss: {
                duration: 3000,
                onScreen: true
              }
            };
            store.addNotification(notification);
          });
      }
    });
  }
  return (
    <div className="row">
      <div className="col-md-12 col-sm-12">
        {/* BEGIN PORTLET*/}

        <Card className="portlet light tasks-widget">
          <CardTitle
            style={{ fontSize: 16, paddingBlock: 10, marginTop: 5 }}
            className="text-center"
          >
            Company Branches
          </CardTitle>
          <div className="portlet-body">
            <div className="table-scrollable table-responsive">
              <table className="table-striped table table-hover">
                <thead>
                  <tr>
                    <th colSpan="2">ID</th>
                    <th colSpan="5">Branch name</th>
                    <th colSpan="5">Branch description</th>
                    <th colSpan="5">Created on</th>
                    <th colSpan="5">Total residents</th>
                    <th colSpan="5">Created by</th>
                    <th colSpan="5">Receptionists</th>
                    <th colSpan="5">Security guards</th>
                    <th colSpan="5">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {branches.slice(pageFrom, pageto).map(branch => {
                    let buttons = null;
                    if (branch.branchStatus === "1") {
                      buttons = (
                        <button
                          type="button"
                          onClick={() => changeBranchStatus(0, branch.branchId)}
                          className="btn  btn-sm btn-danger"
                        >
                          Close
                        </button>
                      );
                    } else {
                      buttons = (
                        <button
                          type="button"
                          onClick={() => changeBranchStatus(1, branch.branchId)}
                          className="btn  btn-sm btn-primary"
                        >
                          Open
                        </button>
                      );
                    }
                    return (
                      <tr key={branch.branchId}>
                        <td colSpan="2">{branch.branchId}</td>
                        <td colSpan="5">{branch.branchName}</td>
                        <td colSpan="5">{branch.description}</td>
                        <td colSpan="5">{branch.timeCreated}</td>
                        <td colSpan="5">{branch.peopleCount}</td>
                        <td colSpan="5">{branch.creator}</td>
                        <td colSpan="5">
                          <span className="badge badge-primary">
                            {branch.receptionists}
                          </span>{" "}
                          Receptionists
                        </td>
                        <td colSpan="5">
                          <span className="badge badge-primary">
                            {branch.guards}
                          </span>{" "}
                          Guards
                        </td>
                        <td colSpan="5">{buttons}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
          <CardFooter
            style={{
              fontSize: 14,
              marginTop: 5,
              PaddingBottom: 30
            }}
          >
            <PagingItems
              setPagingNext={setPagingNext}
              setPagingPrev={setPagingPrev}
            />
          </CardFooter>
        </Card>
      </div>
    </div>
  );
};

export default Branches;
