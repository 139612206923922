import React from "react";

import GuestsComponent from "./list";
import ReceptionDashboard from "./dashboard";
import { useSelector } from "react-redux";
const Guests = props => {
  const guests = useSelector(state => state.visitorsReducer);


  return (
    <React.Fragment>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12 col-sm-12">
            <ReceptionDashboard  />
          </div>
        </div>
      </div>
      <GuestsComponent
        recenttest="Company Guests"
        props={props.data}
        data={guests}
        from={0}
        to={10}
      />
    </React.Fragment>
  );
};

export default Guests;
