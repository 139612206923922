import React, { useEffect, useState, Component } from "react";
import ErrorBoundary from "../Errors/ErrorBoundary";
import ReactDOM from "react-dom";

const root = document.getElementById("modal-root");

class ModalDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return ReactDOM.createPortal(
      <ErrorBoundary>{this.props.children}</ErrorBoundary>,
      root
    );
  }
}

export default ModalDialog;
