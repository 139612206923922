import React, { Component } from "react";
import { Card } from "reactstrap";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false
    };
  }

  static getDerivedStateFromError(error) {
    return {
      hasError: true
    };
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 col-sm-12">
              {/* BEGIN PORTLET*/}
              <Card className="portlet light tasks-widget ">
                <div className="p-5 text-center error-page-text">
                  Contact support
                </div>
              </Card>
            </div>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
