import React, { useEffect } from "react";
import ResidentRegistration from "./registration";
import {
  faUserPlus,
  faUsersCog,
  faUsers,
  faUserAlt
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector } from "react-redux";

const ResidentDashboardComponent = props => {
  let content = <div></div>;
  const residents = useSelector(state => state.residentsReducer);

  const dataState = useSelector(state => state.chooserReducer);

  if (dataState.roleId === "1" || dataState.roleId === "4") {
    content = (
      <div className="guestButton">
        <button
          type="button"
          data-toggle="modal"
          data-target="#expectModal"
          className="btn  btn-sm btn-primary"
          data-toggle="modal"
          data-target="#residentModal"
        >
          <FontAwesomeIcon
            style={{
              color: "#ffffff",
              fontSize: 15,
              padding: 3
            }}
            icon={faUserPlus}
          />
          New Resident
        </button>
      </div>
    );
  }

  return (
    <div className="row margin-top-5">
      <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
        <div className="dashboard-stat2">
          <div className="display">
            <div className="number">
              <h4 className="font-green-sharp">
                <span className="badge  badge-primary">{residents.length}</span>
              </h4>
              <small>Total Residents</small>
            </div>

            <ResidentRegistration dataState={dataState} />
            <div className="icon">
              <i className="fa fa-cubes font-blue-sharp">
                <FontAwesomeIcon
                  style={{
                    color: "#5C9BD1",
                    fontSize: 30
                  }}
                  icon={faUsers}
                />
              </i>
            </div>
          </div>
        </div>
      </div>

      <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
        <div className="dashboard-stat2">
          <div className="display">
            <div className="number">
              <h4 style={{ color: "#1b8f04" }} className="font-green-sharp">
                <span className="badge  badge-success">
                  {
                    residents.filter(
                      i =>
                        i.status === "1" &&
                        i.systemStatus === "1" &&
                        i.branchStatus === "1"
                    ).length
                  }
                </span>
              </h4>
              <small>Active Residents</small>
            </div>
            <div className="icon">
              <i className="fa fa-building font-blue-sharp">
                <FontAwesomeIcon
                  style={{
                    color: "#5C9BD1",
                    fontSize: 30
                  }}
                  icon={faUsersCog}
                />
              </i>
            </div>
          </div>
        </div>
      </div>

      <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
        <div className="dashboard-stat2">
          <div className="display">
            <div className="number">
              <h4 className="font-green-sharp">
                <span className="badge  badge-danger">
                  {
                    residents.filter(
                      i =>
                        i.status !== "1" ||
                        i.systemStatus !== "1" ||
                        i.branchStatus !== "1"
                    ).length
                  }
                </span>
              </h4>
              <small>Inactive Residents</small>
            </div>
            <div className="icon">
              <i className="fa fa-building-o font-blue-sharp">
                <FontAwesomeIcon
                  style={{
                    color: "#5C9BD1",
                    fontSize: 30
                  }}
                  icon={faUserAlt}
                />
              </i>
            </div>
          </div>
        </div>
      </div>

      <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">{content}</div>
    </div>
  );
};

export default React.memo(ResidentDashboardComponent);
