import React, { useState, useEffect } from "react";
import ShimmerLoader from "../Shimmer/shimmer";
import { useSelector } from "react-redux";
import ListItem from "./ListItem";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Swal from "sweetalert2";
import axios from "axios";
import * as ROUTES from "../constants";
const Profile = () => {
  const user = useSelector(state => state.chooserReducer);

  const [username, setUserName] = useState(user.profile.username);
  const [branchName, setBranch] = useState(user.profile.branchName);
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirm] = useState("");
  const [branchDescription, setBranchDescription] = useState(
    user.profile.branchDescription
  );
  const [email, setEmail] = useState(user.profile.userEmail);
  const [about, setAbout] = useState(user.profile.aboutMe);
  const [hidden, spinHide] = useState(true);
  const [disabled, setDisabled] = useState(false);
  const spinner = (
    <FontAwesomeIcon
      className="mr-2 text-success"
      style={{
        fontSize: 20
      }}
      spin
      hidden={hidden}
      icon={faSpinner}
    />
  );
  useEffect(() => {
    setEnabler();
  }, [user]);

  function setEnabler() {
    if (!(user.roleId === "1" || user.roleId === "4")) {
      // setClasValue("form-control mt-1");
    }
  }

  async function updateDetailsEvent(e) {
    e.preventDefault();

    const data = {
      username,
      branchName,
      branchDescription,
      email
    };

    spinHide(false);
    setDisabled(true);
  }

  async function setPasswordEvent(e) {
    e.preventDefault();
    if (!(confirmPassword === newPassword)) {
      Swal.fire("", "Password does not match!", "error");
      return;
    }
    spinHide(false);
    setDisabled(true);

    const data = {
      action: "changePassword",
      current: password,
      password: confirmPassword,
      token: user.token,
      subscriptionId: user.subscriptionId
    };

    await axios
      .post(ROUTES.GLOBAL_URL, data)
      .then(response => {
        spinHide(true);
        setDisabled(false);
        if (response.status === 200) {
          if (response.data.status) {
            setPassword("");
            setConfirm("");
            setNewPassword("");
            Swal.fire("", response.data.message, "success");
          } else {
            Swal.fire("", response.data.message, "warning");
          }
        } else {
          Swal.fire("", "Connection problem", "warning");
        }
      })
      .catch(e => {
        spinHide(true);
        setDisabled(false);
        Swal.fire("", "Connection problem", "warning");
      });
  }

  function clearFields(e) {}
  return (
    <div className="container-fluid my-1 mx-4 p-3 bg-white rounded shadow">
      <div className="row mt-1 ">
        {/* <div className="col-sm-12"> */}
        <div className="col-md-3 col-sm-3">
          <div className="my-2 p-1 bg-white rounded shadow-sm">
            <img
              src="https://image.flaticon.com/icons/svg/921/921071.svg"
              className="img-thumbnail rounded img-responsive"
              alt="Cinque Terre"
            />
            <ListItem name="Name" value={username} />
            <ListItem name="Mobile" value={user.profile.mobileUser} />
            <ListItem name=" Email account" value={email} />
            <ListItem name=" Current Branch" value={branchName} />
            <ListItem
              name=" Member Registration ID"
              value={user.profile.cardNo}
            />
            <div className="media text-muted pt-3 ">
              <p className="media-body pb-1 pl-1 mb-0 small lh-125 border-bottom border-gray">
                <strong className="d-block text-gray-dark">Balance</strong>
                Ksh{user.profile.balance}
                {user.profile.balance >= 100 && (
                  <button className="btn btn-sm float-right mx-1 btn-success">
                    Withdraw
                  </button>
                )}
              </p>
            </div>
            <ListItem name="Role" value={user.profile.roleName} />
            <ListItem name="About" value={about} />
          </div>
        </div>
        <div className="col-md-9 col-sm-9">
          <div className="container-fluid">
            <div className="my-2 p-3 bg-white rounded shadow">
              <h5 className="border-bottom border-gray pb-2 mb-0">
                <strong>personal information</strong>
              </h5>

              <form
                className="residentForm"
                onSubmit={e => updateDetailsEvent(e)}
              >
                <div className="row">
                  <div className="col">
                    <div className="form-group">
                      <div className="media text-muted pt-3 pb-0">
                        <p className="media-body mb-0">
                          <strong className="d-block text-gray-dark">
                            Name
                          </strong>
                          <input
                            type="text"
                            value={username}
                            onChange={e => setUserName(e.target.value)}
                            className="form-control mt-1"
                            placeholder="Username"
                          />
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <div className="form-group">
                      <div className="media text-muted pt-3 mx-0 pb-0">
                        <p className="media-body mb-0">
                          <strong className="d-block text-gray-dark">
                            Branch
                          </strong>
                          <input
                            type="text"
                            value={branchName}
                            onChange={e => setBranch(e.target.value)}
                            className="form-control mt-1 disabled"
                            placeholder="Username"
                          />
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <div className="form-group">
                      <div className="media text-muted pt-3 pb-0">
                        <p className="media-body mb-0">
                          <strong className="d-block text-gray-dark">
                            Phone Number
                          </strong>
                          <input
                            type="text"
                            value={user.profile.mobileUser}
                            className="form-control mt-1 disabled"
                            placeholder="Phone number"
                          />
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row pt-0">
                  <div className="col">
                    <div className="form-group">
                      <div className="media text-muted  pb-0">
                        <p className="media-body mb-0">
                          <strong className="d-block text-gray-dark">
                            Email account
                          </strong>
                          <input
                            type="text"
                            value={email}
                            onChange={e => setEmail(e.target.value)}
                            className="form-control mt-1 disabled"
                            placeholder="Email account"
                          />
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <div className="form-group">
                      <div className="media text-muted  pb-0">
                        <p className="media-body mb-0">
                          <strong className="d-block text-gray-dark">
                            Branch Description
                          </strong>
                          <input
                            type="text"
                            value={branchDescription}
                            onChange={e => setBranchDescription(e.target.value)}
                            className="form-control mt-1 disabled"
                            placeholder="Branch description "
                          />
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <div className="media text-muted  pb-0">
                    <p className="media-body mb-0">
                      <strong className="d-block text-gray-dark">About</strong>
                      <textarea
                        rows="3"
                        type="text"
                        value={about}
                        maxLength="100"
                        onChange={e => setAbout(e.target.value)}
                        height="48"
                        className="form-control mt-1"
                        placeholder="About me"
                      />
                    </p>
                  </div>
                </div>
                <button
                  onClick={e => clearFields(e)}
                  ho
                  className="btn btn-outline-info invisible "
                >
                  Clear
                </button>
                <div className="float-right mr-auto ">
                  <button
                    disabled={disabled}
                    className="btn btn-outline-primary mr-2 invisible"
                  >
                    {spinner}
                    Submit
                  </button>
                </div>
              </form>
            </div>

            <div className="my-2 p-3 bg-white rounded shadow">
              <h5 className="border-bottom border-gray pb-2 mb-0">
                <strong>Change your password</strong>
              </h5>

              <form
                className="residentForm"
                onSubmit={e => setPasswordEvent(e)}
              >
                <div className="form-group">
                  <div className="media text-muted pt-3 pb-0">
                    <p className="media-body mb-0">
                      <strong className="d-block text-gray-dark">
                        Current password
                      </strong>
                      <input
                        type="password"
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                        className="form-control mt-1 change"
                        placeholder="Current password"
                      />
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <div className="form-group">
                      <div className="media text-muted pt-0 pb-0">
                        <p className="media-body mb-0">
                          <strong className="d-block text-gray-dark">
                            New password
                          </strong>
                          <input
                            type="password"
                            value={newPassword}
                            onChange={e => setNewPassword(e.target.value)}
                            className="form-control mt-1 change"
                            placeholder="New password"
                          />
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <div className="form-group">
                      <div className="media text-muted pt-0 pb-0">
                        <p className="media-body mb-0">
                          <strong className="d-block text-gray-dark">
                            Enter your password again
                          </strong>
                          <input
                            type="password"
                            value={confirmPassword}
                            onChange={e => setConfirm(e.target.value)}
                            className="form-control mt-1 change"
                            placeholder="Repeat your new password"
                          />
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <button
                  onClick={e => clearFields(e)}
                  ho
                  className="btn btn-outline-info invisible "
                >
                  Clear
                </button>
                <div className="float-right mr-auto">
                  <button
                    disabled={disabled}
                    className="btn btn-outline-primary mr-2"
                  >
                    {spinner}
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        {/* </div> */}
      </div>
    </div>
  );
};

export default Profile;
