import React, { useEffect, useState } from "react";
import ErrorBoundary from "../Errors/ErrorBoundary";
import ModalDialog from "../Modals/modal";
import Swal from "sweetalert2";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { UnRegulate, visitorsSetter } from "../../actions";
import Search from "../Search/search";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as ROUTES from "../constants";
const ModalBook = () => {
  const dataState = useSelector(state => state.chooserReducer);
  const regulate = useSelector(state => state.guestReducer);
  const [toseeId, setToSeeId] = useState("");
  const [username, setName] = useState("");
  const [userId, setPassport] = useState("");
  const [officeText, setText] = useState("Resident to visit");
  const [mobile, setPhone] = useState("");
  const [items, setItems] = useState("");
  const [readOnly, setReadOnly] = useState(true);
  const [purpose, setPurpose] = useState("");
  const [badge, setBadge] = useState("");
  const [vehicle, setVehicle] = useState("");
  const header = "- Guest Booking";
  const dispatch = useDispatch();
  const disabled = false;
  const spinHidden = true;
  const spin = true;

  useEffect(() => {
    if (regulate) {
      renderProps();
    } else {
      if (dataState.roleId === "5") {
        setText("Office to visit");
      }
    }
  }, [regulate]);

  function renderProps() {
    if (dataState.roleId === "5") {
      setText("Office to visit");
    }
    setName(regulate.name);
    setPassport(regulate.passport);
    setItems(regulate.items);
    setPhone(regulate.mobile);
    setVehicle(regulate.vehicle);
    setBadge(regulate.badge);
    setPurpose(regulate.purpose);
  }

  function clearFields(e) {
    e.preventDefault();
    dispatch(UnRegulate());
    setName("");
    setPassport("");
    setPhone("");
    setItems("");
    setToSeeId("");
    setVehicle("");
    setPurpose("");
    setBadge("");
  }

  const clickRef = React.createRef();

  function setPerson(data) {
    setName(data.person.name);
    setPassport(data.person.passport);
    setPhone(data.person.phone);
  }

  function bookVisitor(e) {
    e.preventDefault();

    const residentData = dataState.residents.filter(
      r => r.subscriptionId === toseeId
    );

    let error = "";
    if (username.length < 3) {
      error = error.concat("Invalid visitor name!\n");
    } else if (mobile.length < 9) {
      error = error.concat("Invalid mobile or telephone number!\n");
    } else if (userId.length < 6) {
      error = error.concat("Invalid Passport or National Identity number!\n");
    } else {
      Swal.fire({
        type: "warning",
        text: "Proceed to add a visitor?",
        showLoaderOnConfirm: true,
        showCancelButton: true,
        preConfirm: async () => {
          const data = {
            action: "addGuest",
            mobile,
            resident: residentData[0].resident,
            items,
            username,
            receptionist: dataState.profile.username,
            presence: 1,
            memberId: residentData[0].memberId,
            telephone: residentData[0].telephone,
            userId,
            vehicle,
            badge,
            purpose,
            regulate: regulate && regulate.incomingId,
            token: dataState.token,
            subscriptionId: dataState.subscriptionId
          };
          await axios
            .post(ROUTES.GUESTS_URL, data)
            .then(response => {
              if (response.data.status) {
                clickRef.current.click();
                dispatch(UnRegulate());
                dispatch(visitorsSetter(response.data.effectVisitor));
              } else {
                alert(response.data.message);
              }
            })
            .catch(() => {
              dispatch(UnRegulate());
            });
        }
      });
    }

    if (error.length > 2) {
      Swal.fire("", error, "warning");
    }
  }

  return (
    <ErrorBoundary>
      <ModalDialog>
        <div className="modal" id="bookModal">
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h3 className="modal-title">{header}</h3>
                {/* <button type="button" className="close" data-dismiss="modal">
                &times;
              </button> */}
              </div>
              <div className="modal-body">
                <Search
                  clearFields={e => clearFields(e)}
                  setPerson={data => setPerson(data)}
                />
                <form onSubmit={e => bookVisitor(e)} className="residentForm">
                  <div className="form-group">
                    <input
                      type="text"
                      value={username}
                      required
                      onChange={e => setName(e.target.value)}
                      className="form-control"
                      placeholder="Enter visitors name (required)"
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      value={mobile}
                      required
                      onChange={e => setPhone(e.target.value)}
                      className="form-control"
                      placeholder="Enter visitors mobile (required)"
                    />
                  </div>

                  <div className="form-group">
                    <input
                      type="text"
                      required
                      value={userId}
                      onChange={e => setPassport(e.target.value)}
                      className="form-control"
                      placeholder="Enter visitors ID/Passport number (required)"
                    />
                  </div>

                  <div className="form-group">
                    <input
                      type="text"
                      value={vehicle}
                      onChange={e => setVehicle(e.target.value)}
                      className="form-control"
                      placeholder="Enter visitors vehicle e.g number plate"
                    />
                  </div>

                  <div className="form-group">
                    <input
                      type="text"
                      value={purpose}
                      required
                      onChange={e => setPurpose(e.target.value)}
                      className="form-control"
                      placeholder="Purpose of visit"
                    />
                  </div>

                  <div className="form-group">
                    <input
                      type="text"
                      value={badge}
                      onChange={e => setBadge(e.target.value)}
                      className="form-control"
                      placeholder="Badge No / card number"
                    />
                  </div>

                  <div className="form-group">
                    <input
                      type="text"
                      value={items}
                      onChange={e => setItems(e.target.value)}
                      className="form-control"
                      placeholder="Enter visitors items (if any)"
                    />
                  </div>

                  <div className="form-group">
                    <select
                      value={toseeId}
                      onChange={e => setToSeeId(e.target.value)}
                      onLoad={e => setToSeeId(e.target.value)}
                      className="form-control mb-3"
                      required
                    >
                      <option value="">{officeText}</option>
                      {dataState.residents.map(opt => {
                        return (
                          <option
                            value={opt.subscriptionId}
                            key={opt.subscriptionId}
                          >
                            {opt.resident}-
                            {opt.roleName === "Company"
                              ? "Office"
                              : opt.roleName}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <button
                    ref={clickRef}
                    onClick={e => clearFields(e)}
                    className="btn btn-outline-info"
                  >
                    Clear
                  </button>
                  <div className="float-right mr-auto">
                    <button
                      className="btn btn-outline-primary mr-2"
                      disabled={disabled}
                    >
                      <FontAwesomeIcon
                        className="mr-1"
                        style={{
                          color: "#5C9BD1",
                          fontSize: 20
                        }}
                        spin={spin}
                        hidden={spinHidden}
                        icon={faSpinner}
                      />
                      Submit
                    </button>

                    <button
                      type="submit"
                      ref={clickRef}
                      onClick={() => dispatch(UnRegulate())}
                      data-dismiss="modal"
                      className="btn btn-outline-danger"
                    >
                      Close
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </ModalDialog>
    </ErrorBoundary>
  );
};

export default ModalBook;
