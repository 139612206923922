import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  faUserMinus,
  faUsers,
  faUserPlus,
  faClock
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { UnRegulate } from "../../actions";
import ModalBook from "./modalBook";
import ModalExpect from "./modalExpect";
import Wrapper from "../Items/wrapper";
import DashboardItem from "../Items/dashboardItem";

const ReceptionDashboard = () => {
  const guests = useSelector(state => state.visitorsReducer);
  const data = useSelector(state => state.chooserReducer);

  const dispatch = useDispatch();

  let content = ( //portal model
    <div className="guestButton">
      <button
        type="button"
        data-toggle="modal"
        data-target="#expectModal"
        className="btn  btn-sm btn-primary"
      >
        <FontAwesomeIcon
          style={{
            color: "#ffffff",
            fontSize: 15,
            padding: 3
          }}
          icon={faUserPlus}
        />
        Expected visitor
      </button>
    </div>
  );

  if (data.roleId === "3" || data.roleId === "5") {
    if (data.roleId === "5") {
      content = (
        <div className="guestButton">
          <button
            type="button"
            onClick={() => dispatch(UnRegulate(null))}
            data-toggle="modal"
            data-target="#bookModal"
            data-toggle="modal"
            data-target="#bookModal"
            data-toggle="modal"
            className="btn btn-sm  btn-danger"
          >
            <FontAwesomeIcon
              style={{
                color: "#ffffff",
                fontSize: 15,
                padding: 3
              }}
              icon={faUserPlus}
            />
            Book visitor
          </button>
        </div>
      );
    } else {
      content = (
        <div className="guestButton">
          <button
            type="button"
            data-toggle="modal"
            data-target="#expectModal"
            className="btn  btn-sm btn-primary"
            data-toggle="modal"
            data-target="#expectModal"
            data-toggle="modal"
          >
            <FontAwesomeIcon
              style={{
                color: "#ffffff",
                fontSize: 15,
                padding: 3
              }}
              icon={faUserPlus}
            />
            Expected visitor
          </button>
          <button
            type="button"
            onClick={() => dispatch(UnRegulate(null))}
            data-toggle="modal"
            data-target="#bookModal"
            data-toggle="modal"
            data-target="#bookModal"
            data-toggle="modal"
            className="btn btn-sm  btn-danger"
          >
            <FontAwesomeIcon
              style={{
                color: "#ffffff",
                fontSize: 15,
                padding: 3
              }}
              icon={faUserPlus}
            />
            Book visitor
          </button>
        </div>
      );
    }
  }
  return (
    <Wrapper>
      <DashboardItem
        text="Guests` In"
        icon={faUserPlus}
        count={guests.filter(i => i.presence === "1").length}
      />
      <DashboardItem
        text="Guests` Out"
        icon={faUserMinus}
        count={
          guests.filter(i => i.presence === "2" || i.presence === "4").length
        }
      />
      <DashboardItem
        text="Expected Guests`"
        icon={faClock}
        count={guests.filter(i => i.presence === "0").length}
      />
      <ModalBook />
      <ModalExpect />
      <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">{content}</div>
    </Wrapper>
  );
};

export default ReceptionDashboard;
