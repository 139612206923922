import React from "react";
import { useDispatch } from "react-redux";
import {
  deleteCompany,
  Logout,
  UnRegulate,
  removeData,
  RemoveFacilities,
  servicesRemover,
  residentsRemover,
  visitorsRemover
} from "../../actions/";
import {
  faTachometerAlt,
  faSpinner,
  faSignOutAlt,
  faTruckMonster,
  faAward,
  faToggleOn,
  faBookOpen,
  faStickyNote,
  faToggleOff,
  faMoneyBill
} from "@fortawesome/free-solid-svg-icons";
import SideWrap from "../Items/SideListWrapper";
import SideBarItem from "../Items/sidebarItem";

const ResidentItem = props => {
  const dispatch = useDispatch();
  function LogoutSystem() {
    dispatch(deleteCompany());
    dispatch(Logout());
    dispatch(UnRegulate());
    dispatch(removeData());
    dispatch(RemoveFacilities());
    dispatch(servicesRemover());
    dispatch(residentsRemover());
    dispatch(visitorsRemover());
  }
  return (
    <SideWrap>
      <SideBarItem
        handleClick={e => props.collectStats(e)}
        text="Dashboard"
        icon={faTachometerAlt}
        to="/"
      />
      <SideBarItem
        handleClick={e => props.collectStats(e)}
        text="Bookings"
        icon={faBookOpen}
        to="/bookings"
      />
      <SideBarItem
        handleClick={e => props.collectStats(e)}
        text="Subscription Payments"
        icon={faMoneyBill}
        to="/subscriptions_payments"
      />
      <SideBarItem
        handleClick={e => props.collectStats(e)}
        text="Feedbacks"
        icon={faAward}
        to="/feedback"
      />
      <SideBarItem
        handleClick={e => props.collectStats(e)}
        text="Service Providers"
        icon={faTruckMonster}
        to="/services"
      />
      <SideBarItem
        handleClick={e => props.collectStats(e)}
        text="Manage Guests"
        icon={faTachometerAlt}
        to="/guests"
      />
      <SideBarItem
        handleClick={e => props.collectStats(e)}
        text="Notifications"
        icon={faStickyNote}
        to="/notifications"
      />
      <SideBarItem
        text="Switch Profile"
        handleClick={() => dispatch(deleteCompany())}
        icon={faToggleOn}
        to="/"
      />
      <SideBarItem
        text="Current profile"
        handleClick={e => props.collectStats(e)}
        icon={faToggleOff}
        to="/profile"
      />
      <SideBarItem
        handleClick={e => props.refreshSystem(e)}
        text="Refresh Application"
        icon={faSpinner}
        to="/"
      />
      <SideBarItem
        text="Logout"
        handleClick={() => LogoutSystem()}
        icon={faSignOutAlt}
        to="/"
      />
    </SideWrap>
  );
};

export default ResidentItem;
