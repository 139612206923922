import React from "react";
import AdminBookings from "./admin";
import ErrorBoundary from "../Errors/ErrorBoundary";
import UserBookings from "./book";
import { useSelector } from "react-redux";

const Bookings = () => {
  const dir = useSelector(state => state.chooserReducer);
  let content = <UserBookings />;

  if (dir === null) {
    content = <UserBookings />;
  } else {
    if (dir.roleId === "1" || dir.roleId === "4") {
      content = <AdminBookings />;
    } else {
      content = <UserBookings />;
    }
  }
  return <ErrorBoundary>{content}</ErrorBoundary>;
};

export default Bookings;
