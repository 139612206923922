import React, { useState } from "react";
import { useEffect } from "react";
import ShimmerLoad from "./loader";
import ShimmerError from "./error";
const ShimmerLoader = (props) => {
  let timer = null;
  const [view, setView] = useState(<ShimmerLoad />);
  useEffect(() => {
    timer = setTimeout(() => {
      setView(<ShimmerError message={props.message}/>);
      console.log("Timeout called!");
    }, 10000);

    return () => clearTimeout(timer);
  }, [timer]);

  if (timer === null) {
    return view;
  }
  return;
};

export default ShimmerLoader;
