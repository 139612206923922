import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import Swal from "sweetalert2";
import { CardFooter } from "reactstrap";
import PagingItems from "../Guests/pagingItems";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { servicesSetter } from "../../actions";
import * as ROUTES from "../constants";
const ServicesLists = () => {
  const dir = useSelector(state => state.servicesReducer);
  const user = useSelector(state => state.chooserReducer);

  const dispatch = useDispatch();

  const [pageto, setPageTo] = useState(7);
  const [pageFrom, setPageFrom] = useState(0);

  function setPagingNext() {
    setPageTo(pageto + 7);
    setPageFrom(pageFrom + 7);
  }

  function setPagingPrev() {
    setPageTo(pageto - 7);
    setPageFrom(pageFrom - 10);
  }

  async function changeServiceStatus(status, serviceId) {
    Swal.fire("", "Deleting service provider", "success");
    const data = {
      action: "changeServiceStatus",
      subscriptionId: user.subscriptionId,
      serviceId,
      status,
      token: user.token
    };
    await axios
      .post(ROUTES.SERVICES_URL, data)
      .then(response => {
        if (response.status === 200) {
          dispatch(servicesSetter(response.data.services));
          Swal.fire("", "Service provider removed successfully!", "success");
          Swal.close();
        } else {
          Swal.fire("", "Service provider was not removed!", "warning");
        }
      })
      .catch(() => {
        Swal.fire(
          "",
          "Check that you have a proper internet connection!",
          "warning"
        );
      });
  }

  function copyContact() {
    Swal.fire("", "Details copied successfully", "success");
  }
  return (
    <div className="portlet-body">
      <div className="table-scrollable table-responsive">
        <table className="table-striped table table-hover">
          <thead>
            <tr>
              <th colSpan="2">ID</th>
              <th colSpan="6">Provider</th>
              <th colSpan="6">Service</th>
              <th colSpan="6">Telephone Number</th>
              <th colSpan="6">Provider created on</th>
              <th colSpan="6">Action</th>
            </tr>
          </thead>
          <tbody>
            {dir.slice(pageFrom, pageto).map(service => {
              let buttons = null;

              if (user.roleId === "1" || user.roleId === "4") {
                buttons = (
                  <button
                    name="Block"
                    onClick={() => changeServiceStatus("0", service.id)}
                    className="btn  btn-sm btn-danger"
                  >
                    Delete
                  </button>
                );
              } else {
                buttons = (
                  <CopyToClipboard
                    onCopy={() => copyContact()}
                    text={`${service.name} : ${service.mobile} - ${service.tagLine}`}
                  >
                    <button className="btn btn-sm btn-info">
                      Copy Contact
                    </button>
                  </CopyToClipboard>
                );
              }
              return (
                <tr key={service.id}>
                  <td colSpan="2">{service.id}</td>
                  <td colSpan="6">{service.name}</td>
                  <td colSpan="6">{service.tagLine}</td>
                  <td colSpan="6">{service.mobile}</td>
                  <td colSpan="6">{service.created_At}</td>
                  <td colSpan="6">{buttons}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      <CardFooter
        style={{
          fontSize: 14,
          marginTop: 5,
          PaddingBottom: 30
        }}
      >
        <PagingItems
          setPagingNext={setPagingNext}
          setPagingPrev={setPagingPrev}
        />
      </CardFooter>
    </div>
  );
};

export default ServicesLists;
