import React, { useState } from "react";
import ModalDialog from "../Modals/modal";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";
import { servicesSetter } from "../../actions";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import $ from "jquery";

import axios from "axios";
import * as ROUTES from "../constants";
const ProviderRegistration = () => {
  const dir = useSelector(state => state.chooserReducer);
  const [service, setService] = useState("");
  const [mobile, setMobile] = useState("");
  const [name, setName] = useState("");

  const [disabled, setDisabled] = useState(false);
  const [spinHidden, setSpinHidden] = useState(true);
  const [spin, setSpin] = useState(true);

  const [header] = useState("- Provider Registration");
  const dispatch = useDispatch();

  const clickRef = React.createRef();


  async function registerProvider(e) {
    e.preventDefault();
    setSpinHidden(false);
    setSpin(true);
    setDisabled(true);

    const data = {
      action: "addServices",
      name,
      tagLine: service,
      mobile,
      web: 0,
      subscriptionId: dir.subscriptionId,
      token: dir.token
    };

    await axios
      .post(ROUTES.SERVICES_URL, data)
      .then(response => {
        if (response.status === 200) {
          dispatch(servicesSetter(response.data.services));
          clearFields();
        } else {
          clearFields();
        }
      })
      .catch(() => {
        Swal.fire("", "Check your internet connection", "warning");
      });
  }

  function clearFields() {
    setName("");
    setMobile("");
    setService("");
    setSpinHidden(true);
    setSpin(false);
    setDisabled(false);
    Swal.fire("", "Provider added successfully!", "success");
    $("#AddServiceModal").modal("hide");
  }

  return (
    <ModalDialog>
      <div className="modal" id="AddServiceModal">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h3 className="modal-title">{header}</h3>
              {/* <button type="button" className="close" data-dismiss="modal">
                &times;
              </button> */}
            </div>
            <div className="modal-body">
              <form
                onSubmit={e => registerProvider(e)}
                className="residentForm"
              >
                <div className="form-group">
                  <input
                    type="text"
                    value={name}
                    required
                    onChange={e => setName(e.target.value)}
                    className="form-control"
                    placeholder="Enter providers name (required)"
                  />
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    value={mobile}
                    required
                    onChange={e => setMobile(e.target.value)}
                    className="form-control"
                    placeholder="Enter providers mobile (required)"
                  />
                </div>

                <div className="form-group">
                  <input
                    type="text"
                    required
                    value={service}
                    onChange={e => setService(e.target.value)}
                    className="form-control"
                    placeholder="Enter service provided (required)"
                  />
                </div>

                <div className="float-right mr-auto">
                  <button
                    type="submit"
                    disabled={disabled}
                    className="btn btn-outline-primary mr-2"
                  >
                    <FontAwesomeIcon
                      className="mr-1"
                      style={{
                        color: "#5C9BD1",
                        fontSize: 20
                      }}
                      spin={spin}
                      hidden={spinHidden}
                      icon={faSpinner}
                    />
                    Submit
                  </button>

                  <button
                    ref={clickRef}
                    data-dismiss="modal"
                    className="btn btn-outline-danger"
                  >
                    Close
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </ModalDialog>
  );
};

export default React.memo(ProviderRegistration);
