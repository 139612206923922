import React from 'react';

const ListItem = (props) => {
    return (   
    <div className="media text-muted pt-3 "> 
              <p className="media-body pb-1 pl-1 mb-0 small lh-125 border-bottom border-gray">
                <strong className="d-block text-gray-dark">{props.name}</strong>
                {props.value}
              </p>  
            </div>);
}
 
export default ListItem;