import React, { Component } from "react";
import ShimmerLoader from "../Shimmer/shimmer";

class Reports extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return <ShimmerLoader message="This is taking longer than expected,kindly try again later!" />;
  }
}

export default Reports;
