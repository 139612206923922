import React, { Component } from "react";
import ShimmerLoader from "../Shimmer/shimmer";
import { messaging } from "../../init-fcm";
import Swal from "sweetalert2";

class UserBookings extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  async componentDidMount() {
    messaging
      .requestPermission()
      .then(async function() {
        const token = await messaging.getToken();
        //send to server
      })
      .catch(function(err) {
        console.log("Unable to get permission to notify.", err);
      });
    navigator.serviceWorker.addEventListener("message", message => {
      const info = message.data["firebase-messaging-msg-data"].data.body;
      const title = message.data["firebase-messaging-msg-data"].data.title;

      Swal.fire("", `${title}-${info}`, "info");
    });
  }

  render() {
    return <ShimmerLoader message="Kindly try again later!" />;
  }
}

export default UserBookings;
