import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const DashboardItem = props => {
  return (
    <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
      <div className="dashboard-stat2">
        <div className="display">
          <div className="number">
            <h4 className="font-green-sharp">{props.count}</h4>
            <small>{props.text}</small>
          </div>
          <div className="icon">
            <i className="fa fa-cubes font-blue-sharp">
              {
                <FontAwesomeIcon
                  style={{
                    color: "#5C9BD1",
                    fontSize: 30
                  }}
                  icon={props.icon}
                />
              }
            </i>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardItem;
