import React from "react";
const SideWrap = props => {
  return (
    <ul
      className="page-sidebar-menu"
      data-keep-expanded="false"
      data-auto-scroll="true"
      data-slide-speed="200"
    >
      {props.children}
    </ul>
  );
};

export default SideWrap;
