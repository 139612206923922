import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Logout,
  chooseCompany,
  visitorsSetter,
  servicesSetter,
  setBranches,
  residentsSetter,
  SetFacilities,
  deleteCompany,
  UnRegulate,
  removeData,
  RemoveFacilities,
  servicesRemover,
  residentsRemover,
  visitorsRemover,
  removeTransactions,
  setTransactions,
  setEvents,
  setComplaints
} from "../actions/";
import ReactNotification from "react-notifications-component";
import avatar from "../assets/ava.png";
import { NavLink } from "reactstrap";
import { Link, useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp } from "@fortawesome/free-solid-svg-icons";
import StrangerItem from "./NavMenuItems/stranger";
import AdminItem from "./NavMenuItems/admin";
import ReceptionItem from "./NavMenuItems/reception";
import ResidentItem from "./NavMenuItems/resident";
import axios from "axios";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import * as ROUTES from "./constants";
const NavMenu = props => {
  const data = useSelector(state => state.chooserReducer);

  const [status, setStatus] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const [spinHidden, setSpinHidden] = useState(true);
  const [spin] = useState(true);
  const [wallet, setWallet] = useState("0");
  const [message, setMessage] = useState(
    " Welcome to the residents management system select a company below to proceed"
  );

  function user_logout() {
    history.push("/");
    dispatch(deleteCompany());
    dispatch(Logout());
    dispatch(UnRegulate());
    dispatch(removeData());
    dispatch(RemoveFacilities());
    dispatch(servicesRemover());
    dispatch(residentsRemover());
    dispatch(visitorsRemover());
    dispatch(removeTransactions());
  }

  async function refreshSystem() {
    setStatus(false);
    setSpinHidden(false);
    setMessage("Your application is refreshing,please wait!");
    const refresh = {
      token: data.token,
      companyId: data.profile.companyId,
      branchId: data.profile.branchId,
      subscriptionId: data.subscriptionId,
      roleId: data.roleId
    };

    await axios
      .post(ROUTES.CHOOSE_URL, refresh)
      .then(response => {
        if (response.status === 200) {
          const res = response.data;
          dispatch(servicesSetter(res.services));
          dispatch(visitorsSetter(res.effectVisitor));
          dispatch(residentsSetter(res.residents));
          dispatch(setTransactions(res.transactions));
          dispatch(setBranches(res.branches));
          dispatch(setEvents(res.events));
          dispatch(setComplaints(res.complaints));
          if (res.roleId === "4" || res.roleId === "1") {
            dispatch(SetFacilities(res.facilities));
          } else {
            dispatch(SetFacilities([]));
          }
          dispatch(chooseCompany(res));
          setSpinHidden(true);
          setMessage("Your application was refreshed successfully!");
        } else {
          setSpinHidden(true);
          setMessage("Unable to refresh,Kindly try again later!");
        }
      })
      .catch(() => {
        setSpinHidden(true);
        setMessage("You do not seem to be connected to the internet!");
      });
  }

  function collectStats() {}
  useEffect(() => {
    setViews();
    return () => {};
  }, [data]);

  function setViews() {
    if (data !== null) {
      setStatus(true);
      setWallet(data.profile.balance);
    }
  }

  let menu = null;
  if (data === null) {
    menu = (
      <StrangerItem
        collectStats={() => collectStats()}
        refreshSystem={() => refreshSystem()}
      />
    );
  } else {
    if (data.roleId === "1" || data.roleId === "4") {
      menu = (
        <AdminItem
          collectStats={() => collectStats()}
          refreshSystem={() => refreshSystem()}
        />
      );
    } else if (data.roleId === "3") {
      menu = (
        <ReceptionItem
          collectStats={() => collectStats()}
          refreshSystem={() => refreshSystem()}
        />
      );
    } else {
      menu = (
        <ResidentItem
          collectStats={() => collectStats()}
          refreshSystem={() => refreshSystem()}
        />
      );
    }
  }

  return (
    <React.Fragment>
      <div className="page-header navbar navbar-fixed-top">
        <div className="page-header-inner">
          <div className="page-logo pt-0 ">
            <NavLink tag={Link} to="/">
              <img
                src="https://rms.changia.co.ke/logo.png"
                alt="Residents Management System"
                className="logoImage"
              />
            </NavLink>
            <div className="menu-toggler sidebar-toggler"></div>
          </div>
          <NavLink
            tag={Link}
            to="/"
            className="menu-toggler responsive-toggler"
            data-toggle="collapse"
            data-target=".navbar-collapse"
          >
            &#9776;
          </NavLink>
          <div className="page-top">
            <div className="top-menu">
              <ul className="nav navbar-nav pull-right">
                <li className="dropdown dropdown-user dropdown-dark mr-auto">
                  <NavLink
                    tag={Link}
                    to="javascript:;"
                    className="dropdown-toggle"
                    data-toggle="dropdown"
                    data-hover="dropdown"
                    data-close-others="false"
                  >
                    <span className="username username-hide-on-mobile">
                      {props.company} | {props.role}
                    </span>
                    <img alt="" style={{ borderRadius: 20 }} src={avatar} />
                  </NavLink>
                  <ul className="dropdown-menu dropdown-menu-default">
                    {/* <li>
                      <NavLink tag={Link} to="/settings">
                        <i className="icon-rocket"></i> Settings
                      </NavLink>
                    </li> */}

                    <li>
                      <NavLink
                        tag={Link}
                        to="/"
                        onClick={() => refreshSystem()}
                      >
                        <i className="icon-rocket"></i> Refresh
                      </NavLink>
                    </li>

                    <li className="divider"></li>
                    <li onClick={() => user_logout()}>
                      <NavLink tag={Link} to="/">
                        <i className="icon-key"></i> Log Out
                      </NavLink>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="clearfix"></div>
      <source src="" />
      <ReactNotification />
      <div className="page-container">
        <div className="page-sidebar-wrapper">
          <div className="page-sidebar navbar-collapse">
            {/* BEGIN SIDEBAR MENU*/}
            {menu}
            {/* END SIDEBAR MENU*/}
          </div>
          <div className="page-content-wrapper">
            <div className="page-content">
              <div
                hidden={status}
                className="alert alert-info alert-dismissible fade show"
                role="alert"
              >
                <FontAwesomeIcon
                  className="float-left mr-1"
                  style={{
                    color: "#ff0000",
                    fontSize: 23
                  }}
                  spin={spin}
                  hidden={spinHidden}
                  icon={faSpinner}
                />
                {message}
                <button
                  type="button"
                  className="close"
                  data-dismiss="alert"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>

              {/* MAIN BODY START */}
              <div className="row">{props.children}</div>
              {/* END MAIN BODY */}
            </div>

            {/* FOOTER START */}
            <footer className="text-center">
              <a
                className="up-arrow"
                href="#myPage"
                data-toggle="tooltip"
                title="TO TOP"
              >
                <span>
                  <FontAwesomeIcon icon={faArrowUp} />
                </span>
              </a>
              <br />
              <br />
              <p>
                Residence Management Solutions
                <a
                  href="  https://rms.changia.co.ke"
                  data-toggle="tooltip"
                  title="Visit w3schools"
                >
                  &nbsp;&nbsp; https://rms.changia.co.ke
                </a>
              </p>
            </footer>
            {/* FOOTER END */}
          </div>
        </div>
        {/* END SIDEBAR*/}
      </div>
    </React.Fragment>
  );
};

export default React.memo(NavMenu);
