import React, { useEffect, useState } from "react";
import ErrorBoundary from "../Errors/ErrorBoundary";
import ModalDialog from "../Modals/modal";
import Swal from "sweetalert2";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { chooseCompany, visitorsSetter } from "../../actions";
import * as ROUTES from "../constants";
import { store } from "react-notifications-component";
import UIfx from "uifx";
import mp3File from "../../assets/point.mp3";

const ModalExpect = props => {
  const dataState = useSelector(state => state.chooserReducer);
  const [resident] = useState("");
  const [username, setName] = useState("");
  const [userId] = useState("");
  const [mobile, setPhone] = useState("");
  const [items] = useState("");
  const [header] = useState("- Expected guest booking");
  const dispatch = useDispatch();
  const beep = new UIfx(mp3File, {
    volume: 1.0, // number between 0.0 ~ 1.0
    throttleMs: 100
  });

  const notification = {
    title: "Success",
    message:
      "Your booking was successful! you will be informed when your guest arrives",
    type: "info",
    insert: "top",
    container: "top-right",
    animationIn: ["animated", "fadeIn"],
    animationOut: ["animated", "fadeOut"],
    dismiss: {
      duration: 5000
    }
  };
  useEffect(() => {}, [props]);

  const clickRef = React.createRef();

  function bookVisitor(e) {
    e.preventDefault();

    let error = "";

    if (username.length < 3) {
      error = error.concat("Invalid visitor name!\n");
    } else if (mobile.length < 9) {
      error = error.concat("Invalid mobile or telephone number!\n");
    } else {
      Swal.fire({
        type: "warning",
        text: "Confirm to book the visitor!",
        showLoaderOnConfirm: true,
        showCancelButton: true,
        confirmButtonClass: "btn btn-sm btn-primary",
        cancelButtonClass: "btn btn-sm btn-danger",
        cancelButtonColor: "#ff0000",

        preConfirm: async () => {
          const data = {
            action: "addGuest",
            mobile,
            resident,
            items: "no item",
            username,
            receptionist: dataState.profile.username,
            presence: 0,
            memberId: dataState.subscriptionId,
            userId,
            vehicle: "",
            token: dataState.token,
            subscriptionId: dataState.subscriptionId
          };

          await axios
            .post(ROUTES.GUESTS_URL, data)
            .then(response => {
              clickRef.current.click();
              if (response.data.status) {
                beep.play();
                store.addNotification(notification);
                dispatch(visitorsSetter(response.data.effectVisitor));
                setPhone("");
                setName("");
                Swal.close();
              } else {
                alert(response.data.message);
              }
            })
            .catch(() => {});
        }
      });
    }

    if (error.length > 2) {
      Swal.fire({
        type: "success",
        text: error,
        showConfirmButton: false,
        showCancelButton: true,
        timer: 1500
      });
    }
  }

  return (
    <ErrorBoundary>
      <ModalDialog>
        <div className="modal" id="expectModal">
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h3 className="modal-title">{header}</h3>
                {/* <button type="button" className="close" data-dismiss="modal">
                &times;
              </button> */}
              </div>
              <div className="modal-body">
                <form onSubmit={e => bookVisitor(e)} className="residentForm">
                  <div className="form-group">
                    <input
                      type="text"
                      value={username}
                      required
                      onChange={e => setName(e.target.value)}
                      className="form-control"
                      placeholder="Enter visitors name (required)"
                    />
                  </div>

                  <div className="form-group">
                    <input
                      type="text"
                      value={mobile}
                      required
                      onChange={e => setPhone(e.target.value)}
                      className="form-control"
                      placeholder="Enter visitors mobile (required)"
                    />
                  </div>

                  <div className="float-right mr-auto">
                    <button
                      type="submit"
                      className="btn btn-outline-primary mr-2"
                    >
                      Submit
                    </button>

                    <button
                      type="submit"
                      ref={clickRef}
                      data-dismiss="modal"
                      className="btn btn-outline-danger"
                    >
                      Close
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </ModalDialog>
    </ErrorBoundary>
  );
};

export default ModalExpect;
