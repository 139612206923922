import React, { useEffect, useState } from "react";
import ErrorBoundary from "../Errors/ErrorBoundary";
import ModalDialog from "../Modals/modal";
import Swal from "sweetalert2";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { UnRegulate, visitorsSetter, setEvents } from "../../actions";
import Search from "../Search/search";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as ROUTES from "../constants";
import $ from "jquery";

import "react-notifications-component/dist/theme.css";
import { store } from "react-notifications-component";
import UIfx from "uifx";
import mp3File from "../../assets/point.mp3";

const ModalAddEvent = () => {
  const dataState = useSelector(state => state.chooserReducer);
  const residents = useSelector(state => state.residentsReducer);
  const [sentTo, setSentTo] = useState("0");
  const [title, setTitle] = useState("");
  const [type, setEventType] = useState("0");
  const [description, setDescription] = useState("");
  const [date, setHeld] = useState("Today");
  const dispatch = useDispatch();
  const [hidden, spinHide] = useState(true);
  const [disabled, setDisabled] = useState(false);
  const beep = new UIfx(mp3File, {
    volume: 1, // number between 0.0 ~ 1.0
    throttleMs: 100
  });

  const spinner = (
    <FontAwesomeIcon
      className="mr-2 text-success"
      style={{
        fontSize: 20
      }}
      spin
      hidden={hidden}
      icon={faSpinner}
    />
  );

  function clearFields(e) {
    e.preventDefault();
    setDescription("");
    setTitle("");
    setSentTo("");
  }

  const clickRef = React.createRef();

  async function sendEvent(e) {
    e.preventDefault();
    spinHide(false);
    setDisabled(true);
    const data = {
      action: "setEvent",
      subscriptionId: dataState.subscriptionId,
      description,
      title,
      sentTo,
      date,
      type,
      token: dataState.token
    };

    await axios.post(ROUTES.GLOBAL_URL, data).then(response => {
      beep.play();
      if (response.status === 200) {
        spinHide(true);
        setDisabled(false);
        dispatch(setEvents(response.data.events));
        $("#eventModal").modal("hide");

        const notification = {
          title: "Success",
          message: "Posted successfully!",
          type: "default",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          timingFunction: "ease-out",
          dismiss: {
            duration: 3000,
            onScreen: true
          }
        };
        store.addNotification(notification);
      } else {
        spinHide(true);
        setDisabled(false);
        const notification = {
          title: "Connection",
          message: "There is an undermining connection problem!",
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          timingFunction: "ease-out",
          dismiss: {
            duration: 3000,
            onScreen: true
          }
        };
        store.addNotification(notification);
      }
    });
  }

  return (
    <ErrorBoundary>
      <ModalDialog>
        <div className="modal" id="eventModal">
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h3 className="modal-title">New Event</h3>
                {/* <button type="button" className="close" data-dismiss="modal">
                &times;
              </button> */}
              </div>
              <div className="modal-body">
                <form onSubmit={e => sendEvent(e)} className="residentForm">
                  <div className="form-group">
                    <select
                      value={type}
                      onChange={e => setEventType(e.target.value)}
                      onLoad={e => setEventType(e.target.value)}
                      className="form-control mb-3"
                      required
                    >
                      {dataState.eventTypes.map(opt => {
                        return (
                          <option value={opt.id} key={opt.id}>
                            {opt.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      value={title}
                      required
                      onChange={e => setTitle(e.target.value)}
                      className="form-control"
                      placeholder="Enter event title (required)"
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      value={description}
                      required
                      onChange={e => setDescription(e.target.value)}
                      className="form-control"
                      placeholder="Enter event description (required)"
                    />
                  </div>
                  <div className="form-group ">
                    <input
                      type="date"
                      value={date}
                      required
                      style={{ width: "100%" }}
                      id="datepicker"
                      onChange={e => setHeld(e.target.value)}
                      className="form-control border p-4 mb-3 bg-white events"
                      placeholder="Enter event date (required)"
                    />
                  </div>
                  <div className="form-group">
                    <select
                      value={sentTo}
                      onChange={e => setSentTo(e.target.value)}
                      onLoad={e => setSentTo(e.target.value)}
                      className="form-control mb-3 "
                      required
                    >
                      <option value="0">Send to all members</option>
                      {residents.map(opt => {
                        return (
                          <option value={opt.memberId} key={opt.subscriptionId}>
                            {opt.resident}-
                            {opt.roleName === "Company"
                              ? "Office"
                              : opt.roleName}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <button
                    ref={clickRef}
                    onClick={e => clearFields(e)}
                    className="btn btn-outline-info"
                  >
                    Clear
                  </button>
                  <div className="float-right mr-auto">
                    <button
                      disabled={disabled}
                      className="btn btn-outline-primary mr-2"
                    >
                      {spinner}
                      Submit
                    </button>

                    <button
                      type="submit"
                      ref={clickRef}
                      data-dismiss="modal"
                      className="btn btn-outline-danger"
                    >
                      Close
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </ModalDialog>
    </ErrorBoundary>
  );
};

export default ModalAddEvent;
