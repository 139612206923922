import React, { useEffect } from "react";
import { Route } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import LoginView from "./components/Login";
import Dashboard from "./components/Dashboard";
import Feedback from "./components/Feedback";
import Guests from "./components/Guests";
import Residents from "./components/Residents";
import Services from "./components/Services";
import Reports from "./components/Reports";
import Companies from "./components/Companies";
import Notifications from "./components/Notifications";
import NavMenu from "./components/NavMenu";
import Payments from "./components/Payments";
import Profile from "./components/Profile";
import Bookings from "./components/Bookings";
import FacilityBookings from "./components/Bookings/bookings";
import "./components/css/pages/css/tasks.css";
import "./components/css/custom.css";
import "./components/css/layout.css";
import "./components/css/pages/css/profile.css";
import "./components/css/pages/css/dashboard.css";
import "./components/Navmenu.css";
import ResidentRequest from "./components/Residents/residentRequest";
import BranchesComponent from "./components/Branches";
import Gallery from "./components/Gallery";
import Subscriptions from "./components/Subscriptions";
import Chat from "./components/Feedback/chat";
import axios from "axios";
import * as ROUTES from "./components/constants";
import "react-notifications-component/dist/theme.css";
import { store } from "react-notifications-component";
import UIfx from "uifx";
import mp3File from "./assets/point.mp3";
import {
  chooseCompany,
  visitorsSetter,
  servicesSetter,
  setBranches,
  residentsSetter,
  SetFacilities,
  setTransactions,
  setEvents,
  setComplaints
} from "./actions/";

function App() {
  let count = 0;
  const dispatch = useDispatch();
  const loggedIn = useSelector(state => state.loginReducer);
  const company = useSelector(state => state.chooserReducer);
  const data = useSelector(state => state.moduleReducer);

  useEffect(() => {}, []);

  const beep = new UIfx(mp3File, {
    volume: 1, // number between 0.0 ~ 1.0
    throttleMs: 100
  });

  navigator.serviceWorker.addEventListener("message", message => {
    try {
      refreshSystem();
      // beep.play();
      const info = message.data["firebase-messaging-msg-data"].data.body;
      const title = message.data["firebase-messaging-msg-data"].data.title;

      const notification = {
        title,
        message: info,
        type: "default",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        timingFunction: "ease-out",
        dismiss: {
          duration: 3000,
          onScreen: true
        }
      };
      store.addNotification(notification);
    } catch (e) {}
  });

  async function refreshSystem() {
    const refresh = {
      companyId: company.profile.companyId,
      branchId: company.profile.branchId,
      subscriptionId: company.subscriptionId,
      roleId: company.roleId,
      token: company.token
    };

    await axios
      .post(ROUTES.CHOOSE_URL, refresh)
      .then(response => {
        if (response.status === 200) {
          const res = response.data;
          dispatch(servicesSetter(res.services));
          dispatch(visitorsSetter(res.effectVisitor));
          dispatch(residentsSetter(res.residents));
          dispatch(setTransactions(res.transactions));
          dispatch(setBranches(res.branches));
          dispatch(setEvents(res.events));
          dispatch(setComplaints(res.complaints));
          if (res.roleId === "4" || res.roleId === "1") {
            dispatch(SetFacilities(res.facilities));
          } else {
            dispatch(SetFacilities([]));
          }
          dispatch(chooseCompany(res));
        }
      })
      .catch(() => {});
  }

  if (loggedIn) {
    if (company === null) {
      if (data != null) {
        return (
          <NavMenu company="Select a subscription to continue" role="Companies">
            <Route
              exact
              path="/"
              render={() => (
                <Companies
                  token={data.token}
                  setCompany
                  companies={data.companies}
                />
              )}
            />
            <Route
              path="/logout"
              render={() => (
                <Companies
                  token={data.token}
                  setCompany
                  companies={data.companies}
                />
              )}
            />

            <Route path="/bookings" render={() => <Bookings />} />
            <Route
              path="/companies"
              render={() => (
                <Companies
                  token={data.token}
                  setCompany
                  companies={data.companies}
                />
              )}
            />
            <Route
              path="/profile"
              render={() => (
                <Companies
                  token={data.token}
                  setCompany
                  companies={data.companies}
                />
              )}
            />

            <Route path="/notifications" render={() => <Notifications />} />
          </NavMenu>
        );
      }
    } else {
      return (
        <NavMenu
          company={`${company.profile.companyName} - ${company.profile.username}`}
          role={company.profile.roleName}
        >
          <Route path="/" exact render={() => <Dashboard data={company} />} />
          <Route path="/dashboard" component={Dashboard} />
          <Route path="/subscriptions_payments" component={Subscriptions} />
          <Route
            exact
            path="/residents"
            render={() => (
              <Residents
                subscriptionId={company.subscriptionId}
                token={company.token}
                residents={company.residents}
              />
            )}
          />
          <Route
            path="/residents/:residentId/:name"
            component={ResidentRequest}
          />

          <Route path="/chat/:complaintId/:senderId/:name" component={Chat} />

          <Route path="/services" component={Services} />
          <Route path="/services/:serviceId" component={Services} />
          <Route path="/reports" component={Reports} />
          <Route path="/notifications" render={() => <Notifications />} />
          <Route
            path="/notifications/:notificationId"
            render={() => <Notifications />}
          />
          <Route path="/guests" render={() => <Guests data={company} />} />
          <Route
            path="/gallery/:guestId/:name/:badge/:purpose/:luggage"
            component={Gallery}
          />
          <Route path="/feedback" component={Feedback} />
          <Route path="/feedback/:feedbackId" component={Feedback} />
          <Route path="/payments" component={Payments} />
          <Route exact path="/bookings" render={() => <Bookings />} />
          <Route path="/facilities" component={Bookings} />
          <Route path="/branches" component={BranchesComponent} />

          <Route
            exact
            path="/bookings/:facilityId"
            component={FacilityBookings}
          />
          <Route
            path="/companies"
            exact
            render={() => <Dashboard data={company} />}
          />
          <Route
            path="/profile"
            render={() => (
              <Profile token={company.token} profile={company.profile} />
            )}
          />
        </NavMenu>
      );
    }
  } else {
    return (
      <React.Fragment>
        <Route path="/" component={LoginView} />
      </React.Fragment>
    );
  }
}

export default React.memo(App);
