import React from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import * as ROUTE from "../constants";
import Swal from "sweetalert2";
import { setEvents } from "../../actions";

const NotificationItem = props => {
  let canVote = "";

  const colors = ["#6f42c1", "#007bff", "#e83e8c"];

  const dir = useSelector(state => state.chooserReducer);

  const dispatch = useDispatch();

  async function sendMotion(eventId, choice, e) {
    const data = {
      action: "memberVote",
      token: dir.token,
      eventId,
      choice,
      subscriptionId: dir.subscriptionId
    };

    await axios.post(ROUTE.GLOBAL_URL, data).then(res => {
      if (res.status === 200) {
        if (res.data.status) {
          Swal.fire("", "You have voted successfully", "success");
          dispatch(setEvents(res.data.events));
        } else {
          Swal.fire("", res.data.message, "warning");
        }
      } else {
        Swal.fire("", "No internet connection!", "warning");
      }
    });
  }
  //spinner-grow text-warning
  var randomColor = colors[Math.floor(Math.random() * colors.length)];

  if (props.eventType === "2") {
    canVote = (
      <div className="btn-group btn-group-sm  clearfix float-right mt-2">
        <button
          data-toggle="modal"
          data-target="#paidModal"
          type="button"
          onClick={e => sendMotion(props.eventId, 1, e)}
          className="btn btn-danger"
        >
          Decline{" "}
          <span className="badge badge-pill badge-white">
            {props.approvals}
          </span>
        </button>

        <button
          type="button"
          onClick={e => sendMotion(props.eventId, 0, e)}
          className="btn text-white btn-success text-white"
        >
          Accept{" "}
          <span className="badge badge-pill badge-white">{props.rejects}</span>
        </button>
      </div>
    );
  }

  return (
    <div
      className={`media text-muted pt-3 ${props.className}`}
      onClick={props.onClick}
    >
      <svg
        className="bd-placeholder-img mr-2 rounded"
        width="32"
        height="32"
        xmlns="https://www.w3.org/2000/svg"
        preserveAspectRatio="xMidYMid slice"
        focusable="false"
        role="img"
        aria-label="Placeholder: 32x32"
      >
        <title>{props.title}</title>
        <rect width="100%" height="100%" fill={randomColor} />
        {/* <text x="50%" y="50%" fill={color[2]} dy=".3em">
          32x32
        </text> */}
      </svg>
      <p className="media-body pb-3 mb-0 small lh-125 border-bottom border-gray">
        <strong className="d-block text-gray-dark">
          {props.title}{" "}
          <i>
            <small>{props.posted_on}</small>
          </i>
        </strong>
        {props.describe} on {props.date}
        {canVote}
      </p>
    </div>
  );
};

export default React.memo(NotificationItem);
