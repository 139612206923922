import React, { useEffect, useState } from "react";
import ModalDialog from "../Modals/modal";
import axios from "axios";
import * as ROUTE from "../constants";
import Swal from "sweetalert2";

const PaidModal = props => {
  const [detail, setDetail] = useState("");
  const [payee, setPayee] = useState("");
  useEffect(() => {
    getInfo();
  }, [props]);

  async function getInfo() {
    const data = {
      action: "getPaymentInfo",
      bookingId: props.bookingId,
      token: 0,
      subscriptionId: 0
    };
    await axios.post(ROUTE.GLOBAL_URL, data).then(response => {
      if (response.status === 200) {
        const res = response.data;
        let detail = `Booking payment confirmed successfully,M-PESA reference number ${res.mpesaCode} from mobile ${res.mpesa_acc} by ${res.senderName} ,amount payable is Ksh${res.mpesaAmount},the user maintains a ticket number of ${res.ticket} for the booking of ${res.facilityName} on ${res.on_date}.`;
        setDetail(detail);
        setPayee(res.facilityName);
      } else {
        Swal.fire("", "No internet connection!", "warning");
      }
    });
  }
  return (
    <ModalDialog>
      <div className="modal" id="paidModal">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="my-3 p-3 bg-white rounded shadow-sm">
              <h6 className="border-bottom border-gray pb-2 mb-0">
                {props.name}`s Booking Payments
              </h6>
              <div className="media text-muted pt-3">
                <svg
                  className="bd-placeholder-img mr-2 rounded"
                  width="32"
                  height="32"
                  xmlns="https://www.w3.org/2000/svg"
                  preserveAspectRatio="xMidYMid slice"
                  focusable="false"
                  role="img"
                  aria-label="Placeholder: 32x32"
                >
                  <title>{props.name}</title>
                  <rect width="100%" height="100%" fill="#007bff" />
                  <text x="50%" y="50%" fill="#007bff" dy=".3em">
                    32x32
                  </text>
                </svg>
                <p className="media-body pb-3 mb-0 small lh-125 border-bottom border-gray">
                  <strong className="d-block text-gray-dark">{payee}</strong>
                  {detail}
                </p>
              </div>
              <div className="float-right mr-auto mt-3">
                <button
                  type="submit"
                  data-dismiss="modal"
                  className="btn btn-outline-danger"
                >
                  Close
                </button>
              </div>
            </div>

            {/* <div className="modal-body">
             
              <div className="float-right mr-auto">
                <button
                  type="submit"
                  data-dismiss="modal"
                  className="btn btn-outline-danger"
                >
                  Close
                </button>
              </div>
            </div>
          */}
          </div>
        </div>
      </div>
    </ModalDialog>
  );
};

export default React.memo(PaidModal);
