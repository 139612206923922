import React, { useState, useEffect } from "react";
import PagingItems from "../Guests/pagingItems";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardTitle, CardFooter, NavLink } from "reactstrap";
import { Link } from "react-router-dom";
import { chooseCompany, residentsSetter } from "../../actions";
import axios from "axios";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";
import * as ROUTES from "../constants";

const ResidentsList = props => {
  let copy = props.data;
  let from = props.from;
  const token = props.token;
  const subscriptionId = props.subscriptionId;
  const user = useSelector(state => state.chooserReducer);
  let to = props.to;

  const [data, setResidents] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    setResidents(props.data);
  }, [props]);

  function filterList(e) {
    let content = e.target.value;
    let list = copy.filter(
      i =>
        i.telephone.toLowerCase().includes(content) ||
        i.name.toLowerCase().includes(content.toLowerCase()) ||
        i.roleName.toLowerCase().includes(content.toLowerCase()) ||
        i.companyName.toLowerCase().includes(content.toLowerCase()) ||
        i.resident.toLowerCase().includes(content.toLowerCase()) ||
        i.cardNo.toLowerCase().includes(content.toLowerCase()) ||
        i.about.toLowerCase().includes(content.toLowerCase())
    );

    list.length > 0 && setResidents(list);
  }

  const [pageto, setPageTo] = useState(to);
  const [pageFrom, setPageFrom] = useState(from);

  function setPagingNext() {
    setPageTo(pageto + 10);
    setPageFrom(pageFrom + 10);
  }

  function setPagingPrev() {
    setPageTo(pageto - 10);
    setPageFrom(pageFrom - 10);
  }

  function showAll() {
    fireSuccess(copy);
    setResidents(copy);
  }

  function showCompanies() {
    let search = copy.filter(i => i.roleId === "4");
    fireSuccess(search);
    setResidents(search);
  }
  function showSelfAdmins() {
    let search = copy.filter(i => i.roleId === "1");
    fireSuccess(search);
    setResidents(search);
  }
  function showGuards() {
    let search = copy.filter(i => i.roleId === "5");
    fireSuccess(search);
    setResidents(search);
  }
  function showResidents() {
    let search = copy.filter(i => i.roleId === "6");
    fireSuccess(search);
    setResidents(search);
  }

  function showStaffs() {
    let search = copy.filter(i => i.roleId === "2");
    fireSuccess(search);
    setResidents(search);
  }

  function showReceptionists() {
    let search = copy.filter(i => i.roleId === "3");
    fireSuccess(search);
    setResidents(search);
  }
  function changeUserStatus(status, memberId, event) {
    Swal.fire({
      text: `Are you sure you want to ${[event.target.name]} this user?.`,
      showCancelButton: true,
      confirmButtonClass: "btn btn-sm btn-warning",
      cancelButtonClass: "btn btn-sm btn-danger",
      confirmButtonText: [event.target.name],
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        const data = {
          action: "changeUserStatus",
          status,
          memberId,
          subscriptionId,
          token
        };
        await axios
          .post(ROUTES.RESIDENTS_URL, data)
          .then(response => {
            response = response.data;
            if (response.status) {
              dispatch(residentsSetter(response.residents));
            } else {
              alert(response.message);
            }
          })
          .catch(() => {
            Swal.fire("", "No internet connection", "warning");
          });
      }
    });
  }

  function fireSuccess(data) {
    Swal.fire("", `Success,found ${data.length} records`, "success");
  }

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-12 col-sm-12">
          {/* BEGIN PORTLET*/}
          <Card className="portlet light tasks-widget">
            <CardTitle
              style={{ fontSize: 16, paddingBlock: 10, marginTop: 5 }}
              className="text-center pt-3"
            >
              Residents{" "}
              <span className="badge badge-primary">{data.length}</span>
              <div className="d-flex p-3  text-white">
                <input
                  className="form-group"
                  id="userInput"
                  type="text"
                  onKeyUp={e => filterList(e)}
                  placeholder="Search.."
                ></input>
                <div className="dropdown dropleft float-right ">
                  <button
                    type="button"
                    className="btn btn-primary dropdown-toggle"
                    data-toggle="dropdown"
                  >
                    Filter By
                  </button>
                  <div className="dropdown-menu">
                    <a
                      onClick={() => showAll()}
                      className="dropdown-item"
                      href="#"
                    >
                      Show all
                    </a>
                    <a
                      onClick={() => showCompanies()}
                      className="dropdown-item"
                      href="#"
                    >
                      Companies
                    </a>
                    <a
                      onClick={() => showSelfAdmins()}
                      className="dropdown-item"
                      href="#"
                    >
                      Admins
                    </a>
                    <a
                      onClick={() => showStaffs()}
                      className="dropdown-item"
                      href="#"
                    >
                      Staffs
                    </a>
                    <a
                      onClick={() => showResidents()}
                      className="dropdown-item"
                      href="#"
                    >
                      Residents
                    </a>
                    <a
                      onClick={() => showGuards()}
                      className="dropdown-item"
                      href="#"
                    >
                      Security Guards
                    </a>
                    <a
                      onClick={() => showReceptionists()}
                      className="dropdown-item"
                      href="#"
                    >
                      Receptionists
                    </a>
                    <span className="dropdown-item-text">
                      To see all members click show all
                    </span>
                  </div>
                </div>
              </div>
            </CardTitle>
            <div className="portlet-body">
              <div className="table-scrollable table-responsive">
                <table
                  id="residentsTable"
                  className="table-striped table table-hover"
                >
                  <thead>
                    <tr>
                      <th colSpan="2">ID</th>
                      <th colSpan="5">Username</th>
                      <th colSpan="5">Mobile number</th>
                      <th colSpan="5">Role</th>
                      <th colSpan="5">Email</th>
                      <th colSpan="5">About</th>
                      <th colSpan="5">ID/Passport</th>
                      <th colSpan="5">Date of enrollment</th>
                      <th colSpan="5">Company Name</th>
                      <th colSpan="5">Company Locations</th>
                      <th colSpan="5">Branch</th>
                      <th colSpan="5">Created by</th>
                      <th colSpan="5">Creator phone</th>
                      <th colSpan="5">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.slice(pageFrom, pageto).map(resident => {
                      let content = (
                        <button
                          type="button"
                          style={{ fontColor: "#fff", width: 70 }}
                          className="btn text-white btn-info text-white"
                        >
                          <NavLink
                            style={{ padding: 0, fontColor: "#fff" }}
                            tag={Link}
                            to={`/residents/${resident.memberId}/${resident.resident}`}
                          >
                            View
                          </NavLink>
                        </button>
                      );

                      let roleValue = null;

                      if (resident.roleId === "1") {
                        roleValue = (
                          <span
                            style={{ fontSize: 12 }}
                            className="badge badge-dark p-2"
                          >
                            Company Admin
                          </span>
                        );
                      } else if (resident.roleId === "2") {
                        roleValue = (
                          <span
                            style={{ fontSize: 12 }}
                            className="badge badge-primary p-2"
                          >
                            Staff
                          </span>
                        );
                      } else if (resident.roleId === "3") {
                        roleValue = (
                          <span
                            style={{ fontSize: 12 }}
                            className="badge badge-danger p-2"
                          >
                            Receptionist
                          </span>
                        );
                      } else if (resident.roleId === "5") {
                        roleValue = (
                          <span
                            style={{ fontSize: 12 }}
                            className="badge badge-info p-2"
                          >
                            Security Officer
                          </span>
                        );
                      } else if (resident.roleId === "4") {
                        roleValue = (
                          <span
                            style={{ fontSize: 12 }}
                            className="badge badge-success p-2"
                          >
                            Company
                          </span>
                        );
                      } else if (resident.roleId === "6") {
                        roleValue = (
                          <span
                            style={{ fontSize: 12 }}
                            className="badge badge-info p-2"
                          >
                            Resident
                          </span>
                        );
                      }

                      if (user.roleId === "1" || user.roleId === "4") {
                        if (
                          resident.status === "1" &&
                          resident.systemStatus === "1" &&
                          resident.branchStatus === "1"
                        ) {
                          content = (
                            <div className="btn-group btn-group-sm">
                              <button
                                style={{ fontColor: "#fff", width: 70 }}
                                type="button"
                                name="Block"
                                onClick={e =>
                                  changeUserStatus(
                                    "0",
                                    resident.subscriptionId,
                                    e
                                  )
                                }
                                className="btn btn-danger"
                              >
                                Block
                              </button>

                              <button
                                type="button"
                                style={{ fontColor: "#fff", width: 70 }}
                                className="btn text-white btn-info text-white"
                              >
                                <NavLink
                                  style={{ padding: 0, fontColor: "#fff" }}
                                  tag={Link}
                                  to={`/residents/${resident.memberId}/${resident.resident}`}
                                >
                                  View
                                </NavLink>
                              </button>
                            </div>
                          );
                        } else {
                          content = (
                            <div className="btn-group btn-group-sm">
                              <button
                                name="Unblock"
                                style={{ width: 70 }}
                                onClick={e =>
                                  changeUserStatus(
                                    "1",
                                    resident.subscriptionId,
                                    e
                                  )
                                }
                                style={{ fontColor: "#fff" }}
                                type="button"
                                className="btn btn-warning text-white"
                              >
                                Unblock
                              </button>
                              <button
                                type="button"
                                style={{ fontColor: "#fff", width: 70 }}
                                className="btn text-white btn-info text-white"
                              >
                                <NavLink
                                  style={{ padding: 0, fontColor: "#fff" }}
                                  tag={Link}
                                  to={`/residents/${resident.memberId}/${resident.resident}`}
                                >
                                  View
                                </NavLink>
                              </button>
                            </div>
                          );
                        }
                      }

                      return (
                        <tr
                          data-toggle="tooltip"
                          title={`This user has a role of a ${resident.roleName}`}
                          key={resident.subscriptionId}
                        >
                          <td colSpan="2">{resident.subscriptionId}</td>
                          <td colSpan="5">{resident.resident}</td>
                          <td colSpan="5">{resident.telephone}</td>
                          <td colSpan="5">{roleValue}</td>
                          <td colSpan="5">{resident.email}</td>
                          <td colSpan="5">{resident.about}</td>
                          <td colSpan="5">{resident.cardNo}</td>
                          <td colSpan="5">{resident.createdOn}</td>
                          <td colSpan="5">{resident.companyName}</td>
                          <td colSpan="5">{resident.companyLocation}</td>
                          <td colSpan="5">{resident.name}</td>
                          <td colSpan="5">{resident.adminName}</td>
                          <td colSpan="5">{resident.adminPhone}</td>
                          <td colSpan="5">{content}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
            <CardFooter
              style={{
                fontSize: 14,
                marginTop: 5,
                PaddingBottom: 30
              }}
            >
              <PagingItems
                setPagingNext={setPagingNext}
                setPagingPrev={setPagingPrev}
              />
            </CardFooter>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default React.memo(ResidentsList);
