import React from "react";
import { useSelector } from "react-redux";
import SubscriptionsList from "./list";
import { CardTitle, Card } from "reactstrap";

const Subscriptions = () => {
  const subscriptions = useSelector(state => state.subscriptionsReducer);
  return (
    <React.Fragment>
      <div className="container-fluid">
        {/* <div className="row">
        <div className="col-md-12 col-sm-12">
          <ServicesDashboard />
        </div>
      </div> */}
        <Card>
          <CardTitle
            style={{ fontSize: 18 }}
            className="text-dark pt-2 text-center"
          >
            Subscriptions Transactions
          </CardTitle>
          <SubscriptionsList />
        </Card>
      </div>
    </React.Fragment>
  );
};

export default Subscriptions;
