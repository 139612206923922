const subscriptionsReducer = (state = null, action) => {
  switch (action.type) {
    case "SETTRANSACTIONS":
      return (state = action.data);
    case "REMOVETRANSACTION":
      return (state = null);
    default:
      return state;
  }
};

export default subscriptionsReducer;
