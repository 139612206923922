import React, { useEffect } from "react";
import GuestsComponent from "../Guests/list";
import DashboardComponent from "./dashboardComponent";
import ReceptionDashboard from "../Guests/dashboard";
import Events from "../Events";
import { useSelector } from "react-redux";
const Dashboard = props => {
  const data = props.data;

  let dashboard = null;

  if (data.roleId === "1" || data.roleId === "4") {
    dashboard = <DashboardComponent data={data} />;
  } else {
    dashboard = (
      <div className="row">
        <div className="col-md-12 col-sm-12">
          <ReceptionDashboard data={data} />
        </div>
      </div>
    );
  }

  return (
    <React.Fragment>
      <div className="container-fluid">
        {/* <div hidden className="d-flex align-items-center p-3 my-3 text-white-50 bg-dark rounded shadow-sm">
          <img
            className="mr-3"
            src="https://rms.changia.co.ke/logo.png"
            alt=""
            width="48"
            height="48"
          />
          <div className="lh-100">
            <h6 className="mb-0 text-white lh-100">Bootstrap</h6>
            <small>Since 2011</small>
          </div>
        </div> */}
        {dashboard}
        <div className="row">
          <GuestsComponent
            recenttest="Recent Guests"
            data={useSelector(state => state.visitorsReducer)}
            from={0}
            to={4}
          />
          ;
        </div>
        <Events events={data.events} />
      </div>
    </React.Fragment>
  );
};

export default Dashboard;
