import React, { Component } from "react";
import ServicesDashboard from "./dashboard";
import ServicesLists from "./ServiceList";
import { Card, CardTitle } from "reactstrap";
import { useSelector } from "react-redux";

class Services extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <React.Fragment>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 col-sm-12">
              <ServicesDashboard />
            </div>
          </div>
          <Card>
            <CardTitle
              style={{ fontSize: 18 }}
              className="text-dark pt-2 text-center"
            >
              Services
            </CardTitle>
            <ServicesLists />
          </Card>
        </div>
      </React.Fragment>
    );
  }
}

export default Services;
