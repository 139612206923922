import React, { useState } from "react";
import Swal from "sweetalert2";
import axios from "axios";
import { useSelector } from "react-redux";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as ROUTES from "../constants";

const Search = props => {
  const [value, setValue] = useState("");
  const dir = useSelector(state => state.chooserReducer);
  const [disabled, setDisabled] = useState(false);
  const [spinHidden, setSpinHidden] = useState(true);

  function getInformation(e) {
    e.preventDefault();
    props.clearFields(e);
    setDisabled(true);
    setSpinHidden(false);
    const data = {
      action: "searchGlobal",
      identity: value,
      subscriptionId: dir.subscriptionId,
      token: dir.token
    };

    axios
      .post(ROUTES.SEARCH_URL, data)
      .then(response => {
        
        if (response.data.status) {
          setDisabled(false);
          setSpinHidden(true);
          Swal.close();
          props.setPerson(response.data);
        } else {
          setDisabled(false);
          setSpinHidden(true);
          Swal.fire("", response.data.message, "warning");
        }
      })
      .catch(e => {
        setDisabled(false);
        setSpinHidden(true);
        Swal.fire("", "No connection", "warning");
      });
  }
  return (
    <form onSubmit={e => getInformation(e)} className="residentForm">
      <div className="form-group">
        <input
          type="text"
          value={value}
          required
          onChange={e => setValue(e.target.value)}
          className="form-control"
          placeholder="Passport /ID quick search (not required)"
        />
      </div>

      <button
        disabled={disabled}
        className="btn btn-sm btn-primary float-right mb-4"
      >
        <FontAwesomeIcon
          className="mr-2"
          style={{
            color: "#fff",
            fontSize: 20
          }}
          spin
          hidden={spinHidden}
          icon={faSpinner}
        />
        Search
      </button>
    </form>
  );
};

export default Search;
