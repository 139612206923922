import React from "react";
import { useDispatch } from "react-redux";
import {
  deleteCompany,
  Logout,
  UnRegulate,
  removeData,
  RemoveFacilities,
  servicesRemover,
  residentsRemover,
  visitorsRemover
} from "../../actions/";
import {
  faTachometerAlt,
  faSpinner,
  faSignOutAlt,
  faUsersCog,
  faTruckMonster,
  faAward,
  faToggleOn,
  faBookOpen,
  faCodeBranch,
  faStickyNote,
  faToggleOff,
  faMoneyBill
} from "@fortawesome/free-solid-svg-icons";
import SideWrap from "../Items/SideListWrapper";
import SideBarItem from "../Items/sidebarItem";

const AdminItem = props => {
  const dispatch = useDispatch();
  function LogoutSystem() {
    dispatch(deleteCompany());
    dispatch(Logout());
    dispatch(UnRegulate());
    dispatch(removeData());
    dispatch(RemoveFacilities());
    dispatch(servicesRemover());
    dispatch(residentsRemover());
    dispatch(visitorsRemover());
  }

  return (
    <SideWrap>
      <SideBarItem
        handleClick={e => props.collectStats(e)}
        text="Admin Dashboard"
        icon={faTachometerAlt}
        to="/"
      />
      <SideBarItem
        handleClick={e => props.collectStats(e)}
        text="Manage Bookings"
        icon={faBookOpen}
        to="/bookings"
      />
      <SideBarItem
        handleClick={e => props.collectStats(e)}
        text="Manage Branches"
        icon={faCodeBranch}
        to="/branches"
      />
      <SideBarItem
        handleClick={e => props.collectStats(e)}
        text="Manage Residents"
        icon={faUsersCog}
        to="/residents"
      />

      <SideBarItem
        handleClick={e => props.collectStats(e)}
        text="Subscription Payments"
        icon={faMoneyBill}
        to="/subscriptions_payments"
      />
      <SideBarItem
        handleClick={e => props.collectStats(e)}
        text="Manage Feedbacks"
        icon={faAward}
        to="/feedback"
      />
      <SideBarItem
        handleClick={e => props.collectStats(e)}
        text="Manage Service Providers"
        icon={faTruckMonster}
        to="/services"
      />
      <SideBarItem
        handleClick={e => props.collectStats(e)}
        text="Manage Guests"
        icon={faTachometerAlt}
        to="/guests"
      />
      <SideBarItem
        text="Switch Profile"
        handleClick={() => dispatch(deleteCompany())}
        icon={faToggleOn}
        to="/"
      />
      <SideBarItem
        text="Current profile"
        handleClick={e => props.collectStats(e)}
        icon={faToggleOff}
        to="/profile"
      />
      <SideBarItem
        handleClick={e => props.refreshSystem(e)}
        text="Refresh Application"
        icon={faSpinner}
        to="/"
      />
      <SideBarItem
        text="Logout"
        handleClick={() => LogoutSystem()}
        icon={faSignOutAlt}
        to="/"
      />
    </SideWrap>
  );
};

export default AdminItem;
