import React from "react";
import { useSelector } from "react-redux";
import {
  faPlusSquare,
  faChartBar,
  faBowlingBall
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BranchModal from "./branchModal";
import Wrapper from "../Items/wrapper";
import DashboardItem from "../Items/dashboardItem";

const BranchesDashboard = () => {
  const dir = useSelector(state => state.branchesReducer);

  return (
    <Wrapper>
      <DashboardItem
        text="Total Branches"
        icon={faChartBar}
        count={dir.length}
      />
      <DashboardItem
        text="Active Branches"
        icon={faPlusSquare}
        count={dir.filter(p => p.branchStatus === "1").length}
      />
      <DashboardItem
        text="Inactive Branches"
        icon={faBowlingBall}
        count={dir.filter(p => p.branchStatus === "0").length}
      />
      <BranchModal />
      <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
        <div className="guestButton">
          <button
            type="button"
            data-toggle="modal"
            data-target="#branchModal"
            className="btn  btn-sm btn-primary"
          >
            <FontAwesomeIcon
              style={{
                color: "#ffffff",
                fontSize: 18,
                padding: 3
              }}
              icon={faPlusSquare}
            />{" "}
            Branch
          </button>
        </div>
      </div>
    </Wrapper>
  );
};

export default BranchesDashboard;
