import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Card, CardTitle, CardFooter, NavLink } from "reactstrap";
import { Link } from "react-router-dom";
import { faPen, faFile } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PagingItems from "./pagingItems";
import axios from "axios";
import Swal from "sweetalert2";
import { chooseCompany, Regulate, visitorsSetter } from "../../actions";
import ShimmerLoader from "../Shimmer/shimmer";
import ModalBook from "./modalBook";
import * as ROUTES from "../constants";
const GuestsComponent = props => {
  const dispatch = useDispatch();
  const data = props.data;

  const [copy, setCopy] = useState(data);
  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");

  const stateSelect = useSelector(state => state.chooserReducer);

  useEffect(() => {
    setCopy(props.data);
  }, [props.data]);

  let from = props.from;
  let to = props.to;

  const [pageto, setPageTo] = useState(to);
  const [pageFrom, setPageFrom] = useState(from);
  const [modalProps] = useState(null);
  const [modalState, setModalState] = useState(false);

  function setProps(
    name,
    passport,
    items,
    mobile,
    incomingId = null,
    vehicle,
    purpose,
    badge
  ) {
    setModalState(true);
    dispatch(
      Regulate({
        name,
        passport,
        items,
        mobile,
        incomingId,
        vehicle,
        purpose,
        badge
      })
    );
  }

  function setPagingNext() {
    setPageTo(pageto + 10);
    setPageFrom(pageFrom + 10);
  }

  function setPagingPrev() {
    setPageTo(pageto - 10);
    setPageFrom(pageFrom - 10);
  }

  function BookGuest(visitorId, username) {
    Swal.mixin({
      input: "text",
      confirmButtonText: "Next   &rarr;",
      showCancelButton: true,
      progressSteps: ["1", "2"],
      confirmButtonClass: "btn btn-sm btn-primary",
      cancelButtonClass: "btn btn-sm btn-danger",
      cancelButtonColor: "#ff0000"
    })
      .queue([
        {
          title: `National ID /Passport number : for (${username})`,
          input: "text"
        },
        {
          title: `If (${username}) has any items input them`,
          input: "text",
          inputClass: " mb-2 mr-sm-2",
          confirmButtonText: "Finish"
        }
      ])
      .then(result => {
        if (result.value) {
          let userId = result.value[0];
          let items = result.value[1];

          const data = {
            action: "arrivedGuest",
            visitorId,
            userId,
            items,
            subscriptionId: stateSelect.subscriptionId,
            token: stateSelect.token
          };
          Swal.fire({
            text: "Confirm to book the visitor",
            confirmButtonText: "Complete",
            confirmButtonClass: "btn btn-sm btn-primary",
            cancelButtonClass: "btn btn-sm btn-danger",
            cancelButtonColor: "#ff0000",
            showLoaderOnConfirm: true,
            preConfirm: async () => {
              try {
                await axios
                  .post(ROUTES.GUESTS_URL, data)
                  .then(response => {
                    response = response.data;

                    if (response.status) {
                      dispatch(visitorsSetter(response.effectVisitor));
                      Swal.fire("", response.message, "success");
                    } else {
                      Swal.fire("", "Something went wrong", "warning");
                    }
                  })
                  .catch(() => {
                    Swal.fire("", "No internet connection", "warning");
                  });
              } catch (e) {
                Swal.fire("", "No internet connection", "warning");
              }
            }
          });
        }
      });
  }

  function DeclineVisitor(visitorId, username) {
    const data = {
      action: "deleteVisitor",
      visitorId,
      subscriptionId: stateSelect.subscriptionId,
      token: stateSelect.token
    };

    Swal.fire({
      type: "confirm",
      confirmButtonText: "Finish  &rarr;",
      showCancelButton: true,
      confirmButtonClass: "btn btn-sm btn-danger",
      cancelButtonClass: "btn btn-sm btn-secondary",
      cancelButtonColor: "#ff0000",
      showLoaderOnConfirm: true,
      text: `Are you sure you want to unbook ${username}`,
      preConfirm: async () => {
        try {
          await axios
            .post(ROUTES.GUESTS_URL, data)
            .then(response => {
              response = response.data;

              if (response.status) {
                dispatch(visitorsSetter(response.effectVisitor));

                Swal.fire({
                  type: "success",
                  showConfirmButton: false,
                  timer: 2500,
                  text: response.message
                });
              } else {
                alert(response.message);
                Swal.fire({
                  title: "Something went wrong",
                  type: "error",
                  showConfirmButton: false,
                  timer: 1500,
                  text: response.message
                });
              }
            })
            .catch(() => {
              Swal.fire({
                title: "Error",
                type: "error",
                text: "No internet connection"
              });
            });
        } catch (e) {
          Swal.fire({
            title: "Error",
            type: "error",
            text: "No internet connection"
          });
        }
      }
    });
  }

  function ItemPush(visitorId, username) {
    Swal.mixin({
      input: "text",
      confirmButtonText: "Next   &rarr;",
      showCancelButton: true,
      progressSteps: ["1"],
      confirmButtonClass: "btn btn-sm btn-primary",
      cancelButtonClass: "btn btn-sm btn-danger",
      cancelButtonColor: "#ff0000"
    })
      .queue([
        {
          title: `If you want to assign any items to (${username}) input them`,
          input: "text",
          inputClass: "mb-2 mr-sm-2 pl-3",
          confirmButtonText: "Finish"
        }
      ])
      .then(result => {
        if (result.value) {
          let items = result.value[0];

          const data = {
            action: "addLuggage",
            visitorId,
            items,
            subscriptionId: stateSelect.subscriptionId,
            token: stateSelect.token
          };
          Swal.fire({
            text: `Confirm to add items to ${username}!`,
            confirmButtonText: "Complete",
            confirmButtonClass: "btn btn-sm btn-primary",
            cancelButtonClass: "btn btn-sm btn-danger",
            cancelButtonColor: "#ff0000",
            showLoaderOnConfirm: true,
            preConfirm: async () => {
              try {
                await axios
                  .post(ROUTES.GUESTS_URL, data)
                  .then(response => {
                    response = response.data;

                    if (response.status) {
                      dispatch(visitorsSetter(response.effectVisitor));
                      Swal.fire("", response.message, "success");
                    } else {
                      // alert(response.message);
                      Swal.fire("", response.message, "error");
                    }
                  })
                  .catch(() => {
                    Swal.fire({
                      title: "Error",
                      type: "error",
                      text: "No internet connection"
                    });
                  });
              } catch (e) {
                Swal.fire({
                  title: "Error",
                  type: "error",
                  text: "No internet connection"
                });
              }
            }
          });
        }
      });
  }

  function ApproveRelease(visitorId, username) {
    const data = {
      action: "releaseVisitor",
      visitorId,
      subscriptionId: stateSelect.subscriptionId,
      token: stateSelect.token
    };

    Swal.fire({
      type: "question",
      confirmButtonText: "Finish  &rarr;",
      showCancelButton: true,
      confirmButtonClass: "btn btn-sm btn-danger",
      cancelButtonClass: "btn btn-sm btn-secondary",
      cancelButtonColor: "#ff0000",
      showLoaderOnConfirm: true,
      text: `Are you sure you want to release ${username}`,
      preConfirm: async () => {
        try {
          await axios
            .post(ROUTES.GUESTS_URL, data)
            .then(response => {
              response = response.data;

              if (response.status) {
                dispatch(visitorsSetter(response.effectVisitor));

                Swal.fire("", response.message, "success");
              } else {
                alert(response.message);
                Swal.fire("", "Something went wrong", "warning");
              }
            })
            .catch(e => {
              Swal.fire("", "No internet connection", "warning");
            });
        } catch (e) {
          Swal.fire("", "No internet connection", "warning");
        }
      }
    });
  }

  function ReleaseGuest(visitorId, username) {
    const data = {
      action: "releaseVisitor",
      visitorId,
      subscriptionId: stateSelect.subscriptionId,
      token: stateSelect.token
    };

    Swal.fire({
      type: "question",
      confirmButtonText: "Finish  &rarr;",
      showCancelButton: true,
      confirmButtonClass: "btn btn-sm btn-danger",
      cancelButtonClass: "btn btn-sm btn-secondary",
      cancelButtonColor: "#ff0000",
      showLoaderOnConfirm: true,
      text: `Are you sure you want to release ${username}`,
      preConfirm: async () => {
        try {
          await axios
            .post(ROUTES.GUESTS_URL, data)
            .then(response => {
              response = response.data;

              if (response.status) {
                dispatch(visitorsSetter(response.effectVisitor));
                Swal.fire("", response.message, "success");
              } else {
                alert(response.message);
                Swal.fire(
                  "",
                  "Seomething went wrong,try again later!",
                  "warning"
                );
              }
            })
            .catch(e => {
              Swal.fire("", "No internet connection", "warning");
            });
        } catch (e) {
          Swal.fire("", "No internet connection", "warning");
        }
      }
    });
  }

  function showAll() {
    setPageTo(10);
    setPageFrom(0);
    setCopy(data);
  }

  function showCheckedIn() {
    setPageTo(10);
    setPageFrom(0);
    let list = data.filter(i => i.presence === "1");
    list.length > 0 && setCopy(list);
  }
  function showCheckedOut() {
    setPageTo(10);
    setPageFrom(0);
    let search = data.filter(i => i.presence === "2" || i.presence === "4");
    setCopy(search);
  }
  function showExpected() {
    setPageTo(10);
    setPageFrom(0);
    let list = data.filter(i => i.presence === "0");
    list.length > 0 && setCopy(list);
  }
  function showLast5() {
    setPageTo(10);
    setPageFrom(0);
    let search = data.slice(0, 5);
    setCopy(search);
  }

  function filterList(e) {
    let content = e.target.value;

    let list = data.filter(
      visitor =>
        visitor.telephone.includes(content) ||
        visitor.username.toLowerCase().includes(content.toLowerCase()) ||
        visitor.invitedBy.toLowerCase().includes(content.toLowerCase()) ||
        visitor.guardName.toLowerCase().includes(content.toLowerCase()) ||
        visitor.guardPhone.toLowerCase().includes(content.toLowerCase()) ||
        visitor.toSeePhone.includes(content.toLowerCase()) ||
        visitor.uniqueCard.toLowerCase().includes(content.toLowerCase()) ||
        visitor.luggage.toLowerCase().includes(content.toLowerCase()) ||
        visitor.selfItems.toLowerCase().includes(content.toLowerCase())
    );

    list.length > 0 && setCopy(list);
  }

  function dateFilter() {
    if (start.length < 3) {
      Swal.fire("", "Select a starting date", "warning");
    } else if (end.length < 3) {
      Swal.fire("", "Select an ending date", "warning");
    } else {
      let list = data.filter(
        i =>
          Date.parse(i.dateIn) >= Date.parse(start) &&
          Date.parse(i.dateIn) <= Date.parse(end)
      );

      list.length > 0 && setCopy(list);
    }
  }

  if (copy === undefined) {
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12 col-sm-12">
            <ModalBook modalProps={modalProps} modalState={modalState} />
            <Card className="portlet light tasks-widget">
              <CardTitle
                style={{ fontSize: 16, paddingBlock: 10, marginTop: 5 }}
                className="text-center"
              >
                <h6 className="border-bottom border-gray pb-2 mb-0">
                  {props.recenttest}
                </h6>

                <div className="d-flex p-3  text-white">
                  <input
                    className="form-group"
                    id="userInput"
                    type="text"
                    onKeyUp={e => filterList(e)}
                    placeholder="Search..."
                  />
                  <div className="dropdown dropleft float-right ">
                    <button
                      type="button"
                      className="btn btn-primary dropdown-toggle"
                      data-toggle="dropdown"
                    >
                      Filter By
                    </button>
                    <div className="dropdown-menu">
                      <a onClick={() => showAll()} className="dropdown-item">
                        Show all
                      </a>
                      <a
                        onClick={() => showExpected()}
                        className="dropdown-item"
                      >
                        Expected
                      </a>
                      <a
                        onClick={() => showCheckedIn()}
                        className="dropdown-item"
                      >
                        Checked In
                      </a>
                      <a
                        onClick={() => showCheckedOut()}
                        className="dropdown-item"
                      >
                        Checked Out
                      </a>
                      <a onClick={() => showLast5()} className="dropdown-item">
                        Last 5
                      </a>
                      <span className="dropdown-item-text">
                        To see all members click show all
                      </span>
                    </div>
                  </div>
                </div>
              </CardTitle>
              <ShimmerLoader message="Kindly try again later!" />;
            </Card>
          </div>
        </div>
      </div>
    );
  } else {
    if (copy.length === 0) {
      return <ShimmerLoader message="Kindly try again later!" />;
    }
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12 col-sm-12">
            {/* BEGIN PORTLET*/}
            <ModalBook modalProps={modalProps} modalState={modalState} />
            <Card className="portlet light tasks-widget">
              <CardTitle
                style={{ fontSize: 16, paddingBlock: 10, marginTop: 5 }}
                className="text-center"
              >
                {props.recenttest}{" "}
                <span className="badge badge-primary">{copy.length}</span>
                <div className="d-flex p-2">
                  <span className="pt-3">From Date</span>
                  <input
                    value={start}
                    onChange={e => setStart(e.target.value)}
                    className="form-group"
                    type="date"
                    name="start"
                  />{" "}
                  <span className="pt-3">To Date</span>
                  <input
                    value={end}
                    onChange={e => setEnd(e.target.value)}
                    className="form-group"
                    type="date"
                    name="start"
                  />
                  <button
                    type="button"
                    onClick={() => dateFilter()}
                    style={{ height: 40 }}
                    className="btn btn-info btn-sm"
                  >
                    Filter
                  </button>
                  <input
                    className="form-group"
                    id="userInput"
                    type="text"
                    onKeyUp={e => filterList(e)}
                    placeholder="Search.."
                  ></input>
                  <div className="dropdown dropleft float-right ">
                    <button
                      type="button"
                      className="btn btn-primary dropdown-toggle"
                      data-toggle="dropdown"
                    >
                      Filter By
                    </button>
                    <div className="dropdown-menu">
                      <a
                        onClick={() => showAll()}
                        className="dropdown-item"
                        href="#"
                      >
                        Show all
                      </a>
                      <a
                        onClick={() => showExpected()}
                        className="dropdown-item"
                        href="#"
                      >
                        Expected
                      </a>
                      <a
                        onClick={() => showCheckedIn()}
                        className="dropdown-item"
                        href="#"
                      >
                        Checked In
                      </a>
                      <a
                        onClick={() => showCheckedOut()}
                        className="dropdown-item"
                        href="#"
                      >
                        Checked Out
                      </a>
                      <a
                        onClick={() => showLast5()}
                        className="dropdown-item"
                        href="#"
                      >
                        Last 5
                      </a>
                      <span className="dropdown-item-text">
                        To see all members click show all
                      </span>
                    </div>
                  </div>
                </div>
              </CardTitle>
              <div className="portlet-body">
                <div className="table-scrollable table-responsive">
                  <table className="table-striped table table-hover">
                    <thead>
                      <tr>
                        <th colSpan="2">ID</th>
                        <th colSpan="3">Visitor</th>
                        <th colSpan="3">Added By</th>
                        <th colSpan="5">Purpose of visit</th>
                        <th colSpan="3">Invited By</th>
                        <th colSpan="5">Items</th>
                        <th colSpan="3">Vehicle</th>
                        <th colSpan="3">Booking time</th>
                        <th colSpan="3">Leaving time</th>
                        <th colSpan="5"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {copy.slice(pageFrom, pageto).map(visitor => {
                        let type = null;

                        if (visitor.guardType === "5") {
                          type = (
                            <span className="badge badge-info p-1">
                              Security Officer
                            </span>
                          );
                        } else if (visitor.guardType === "3") {
                          type = (
                            <span className="badge badge-danger p-1">
                              Receptionist
                            </span>
                          );
                        }

                        let content = (
                          <button
                            type="button"
                            style={{ fontColor: "#fff" }}
                            className="btn btn-sm btn-info disabled"
                          >
                            No action
                          </button>
                        );

                        if (stateSelect.roleId === "5") {
                          //guard logged in
                          if (visitor.presence === "0") {
                            //visitor is expected by a company

                            if (visitor.guardType !== "5") {
                              content = (
                                <button
                                  type="button"
                                  onClick={() =>
                                    BookGuest(
                                      visitor.visitorId,
                                      visitor.username
                                    )
                                  }
                                  className="btn btn-sm btn-info"
                                >
                                  Book now
                                </button>
                              );
                            } else {
                              content = (
                                <button className="btn btn-sm btn-success">
                                  Received
                                </button>
                              );
                            }
                          } else if (visitor.presence === "1") {
                            //checked in guest
                            if (
                              visitor.invitedById === stateSelect.profile.userId
                            ) {
                              if (visitor.selfItems === "") {
                                content = (
                                  <button
                                    onClick={() =>
                                      ItemPush(
                                        visitor.visitorId,
                                        visitor.username
                                      )
                                    }
                                    type="button"
                                    className="btn btn-sm btn-primary"
                                  >
                                    <FontAwesomeIcon
                                      style={{
                                        color: "#ffffff",
                                        fontSize: 15,
                                        padding: 3,
                                        marginLeft: 2
                                      }}
                                      icon={faPen}
                                    />
                                    Items
                                  </button>
                                );
                              } else {
                                content = (
                                  <button
                                    onClick={() =>
                                      ReleaseGuest(
                                        visitor.visitorId,
                                        visitor.username
                                      )
                                    }
                                    type="button"
                                    className="btn btn-sm btn-danger"
                                  >
                                    Check out
                                  </button>
                                );
                              }
                            } else {
                              content = (
                                <button
                                  onClick={() =>
                                    ReleaseGuest(
                                      visitor.visitorId,
                                      visitor.username
                                    )
                                  }
                                  type="button"
                                  className="btn btn-sm btn-danger"
                                >
                                  Check out
                                </button>
                              );
                            }
                          } else if (visitor.presence === "2") {
                            content = (
                              <button
                                onClick={() =>
                                  ApproveRelease(
                                    visitor.visitorId,
                                    visitor.username
                                  )
                                }
                                type="button"
                                className="btn text-white btn-sm btn-warning"
                              >
                                Approve release
                              </button>
                            );
                          } else if (visitor.presence === "4") {
                            content = (
                              <button
                                type="button"
                                onClick={() =>
                                  setProps(
                                    visitor.username,
                                    visitor.uniqueCard,
                                    visitor.selfItems,
                                    visitor.telephone,
                                    0,
                                    visitor.vehicle,
                                    visitor.purpose,
                                    visitor.badge
                                  )
                                }
                                style={{ fontColor: "#fff" }}
                                className="btn btn-sm btn-primary"
                                data-toggle="modal"
                                data-target="#bookModal"
                                data-toggle="modal"
                              >
                                Check In
                              </button>
                            );
                          }
                        } else if (stateSelect.roleId === "3") {
                          //receptionist logged in
                          if (visitor.presence === "0") {
                            //visitor is expected by a company

                            if (visitor.guardType === "5") {
                              content = (
                                <button
                                  type="button"
                                  onClick={() =>
                                    setProps(
                                      visitor.username,
                                      visitor.uniqueCard,
                                      visitor.selfItems,
                                      visitor.telephone,
                                      visitor.visitorId,
                                      visitor.vehicle,
                                      visitor.purpose,
                                      visitor.badge
                                    )
                                  }
                                  style={{ fontColor: "#fff" }}
                                  className="btn btn-sm btn-info"
                                  data-toggle="modal"
                                  data-target="#bookModal"
                                  data-toggle="modal"
                                >
                                  Expected
                                </button>
                              );
                            } else {
                              content = (
                                <button
                                  type="button"
                                  onClick={() =>
                                    BookGuest(
                                      visitor.visitorId,
                                      visitor.username
                                    )
                                  }
                                  className="btn btn-sm btn-success"
                                >
                                  Book now
                                </button>
                              );
                            }
                          } else if (visitor.presence === "1") {
                            //checked in guest
                            if (
                              visitor.invitedById ===
                                stateSelect.profile.userId &&
                              visitor.status === "1"
                            ) {
                              if (
                                visitor.selfItems === "" ||
                                visitor.selfItems === "none"
                              ) {
                                content = (
                                  <button
                                    onClick={() =>
                                      ItemPush(
                                        visitor.visitorId,
                                        visitor.username
                                      )
                                    }
                                    type="button"
                                    className="btn btn-sm btn-primary"
                                  >
                                    <FontAwesomeIcon
                                      style={{
                                        color: "#ffffff",
                                        fontSize: 15,
                                        padding: 3,
                                        marginLeft: 2
                                      }}
                                      icon={faPen}
                                    />
                                    Items
                                  </button>
                                );
                              } else {
                                content = (
                                  <button
                                    onClick={() =>
                                      ReleaseGuest(
                                        visitor.visitorId,
                                        visitor.username
                                      )
                                    }
                                    type="button"
                                    className="btn btn-sm btn-danger"
                                  >
                                    Release
                                  </button>
                                );
                              }
                            } else {
                              content = (
                                <button
                                  onClick={() =>
                                    ReleaseGuest(
                                      visitor.visitorId,
                                      visitor.username
                                    )
                                  }
                                  type="button"
                                  className="btn btn-sm btn-danger"
                                >
                                  Release
                                </button>
                              );
                            }
                          } else if (
                            visitor.presence === "2" ||
                            visitor.presence === "4"
                          ) {
                            content = (
                              <button
                                type="button"
                                onClick={() =>
                                  setProps(
                                    visitor.username,
                                    visitor.uniqueCard,
                                    visitor.selfItems,
                                    visitor.telephone,
                                    0,
                                    visitor.vehicle,
                                    visitor.purpose,
                                    visitor.badge
                                  )
                                }
                                style={{ fontColor: "#fff" }}
                                className="btn btn-sm btn-primary"
                                data-toggle="modal"
                                data-target="#bookModal"
                                data-toggle="modal"
                              >
                                Check In
                              </button>
                            );
                          }
                        } else {
                          if (
                            visitor.invitedById === stateSelect.profile.userId
                          ) {
                            if (visitor.presence === "1") {
                              content = (
                                <button
                                  onClick={() =>
                                    ItemPush(
                                      visitor.visitorId,
                                      visitor.username
                                    )
                                  }
                                  type="button"
                                  className="btn btn-sm btn-primary"
                                >
                                  <FontAwesomeIcon
                                    style={{
                                      color: "#ffffff",
                                      fontSize: 15,
                                      padding: 3,
                                      marginLeft: 2
                                    }}
                                    icon={faPen}
                                  />
                                  Items
                                </button>
                              );
                            } else if (visitor.presence === "0") {
                              content = (
                                <button
                                  onClick={() =>
                                    DeclineVisitor(
                                      visitor.visitorId,
                                      visitor.username
                                    )
                                  }
                                  type="button"
                                  className="btn btn-sm btn-danger"
                                >
                                  Decline
                                </button>
                              );
                            }
                          }
                        }
                        let uploads = null;
                        if (visitor.hasUploads === "1") {
                          uploads = (
                            <button className="btn btn-sm btn-info ml-2">
                              {/* <FontAwesomeIcon className="mr-2" icon={faFile} /> */}
                              <NavLink
                                className="text-white p-0"
                                tag={Link}
                                to={`/gallery/${visitor.visitorId}/${visitor.username}/${visitor.badge}/${visitor.purpose}/${visitor.luggage}}`}
                              >
                                uploads
                              </NavLink>
                            </button>
                          );
                        }

                        return (
                          <tr key={visitor.visitorId}>
                            <td colSpan="2">{visitor.visitorId}</td>
                            <td colSpan="3">
                              {visitor.username}
                              <br />
                              {visitor.telephone}
                              <br />
                              <span className="badge badge-primary p-2 text-white">
                                Visitor {visitor.badge}
                              </span>
                            </td>
                            <td colSpan="3">
                              {visitor.guardName}
                              <br />
                              {visitor.guardPhone}
                              <br />
                              {type}
                            </td>
                            <td colSpan="5"> {visitor.purpose}</td>
                            <td colSpan="3">
                              {visitor.invitedBy}
                              <br />
                              {visitor.toSeePhone}
                            </td>
                            <td colSpan="5">
                              Personal Items :{" "}
                              {visitor.selfItems.length > 0
                                ? visitor.selfItems
                                : "Has no personal items"}
                              <br />
                              Other Items: {visitor.luggage}
                              <br />
                            </td>
                            <td colSpan="3">{visitor.vehicle}</td>
                            <td colSpan="3">{visitor.dateIn}</td>
                            <td colSpan="3">{visitor.checkOutDate}</td>
                            <td colSpan="5">
                              <div className="d-flex">
                                {content}
                                {uploads}
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
              <CardFooter
                style={{
                  fontSize: 14,
                  marginTop: 5,
                  PaddingBottom: 30
                }}
              >
                <PagingItems
                  setPagingNext={setPagingNext}
                  setPagingPrev={setPagingPrev}
                />
              </CardFooter>
            </Card>
          </div>
        </div>
      </div>
    );
  }
};

export default GuestsComponent;
