import React, { useState } from "react";
import PagingItems from "../Guests/pagingItems";
import { useSelector, useDispatch } from "react-redux";
import { Card, CardTitle, CardFooter } from "reactstrap";
import axios from "axios";
import * as ROUTES from "../constants";
import Swal from "sweetalert2";
import { SetFacilities } from "../../actions";
import PaidModal from "./paidModal";

const FacilityBookings = ({ match }) => {
  let from = 0;
  let to = 12;
  const dir = useSelector(state => state.bookingReducer);
  const user = useSelector(state => state.chooserReducer);
  const dispatch = useDispatch();
  const [name, setName] = useState("");
  const [bookingId, setBookingId] = useState("");

  let data = dir.filter(f => f.id === match.params.facilityId);

  const [pageto, setPageTo] = useState(to);
  const [pageFrom, setPageFrom] = useState(from);

  function setPagingNext() {
    setPageTo(pageto + 12);
    setPageFrom(pageFrom + 12);
  }

  function setPagingPrev() {
    setPageTo(pageto - 12);
    setPageFrom(pageFrom - 12);
  }

  async function bookUser(transactionsId, facilityId, ticket) {
    const data = {
      action: "bookUser",
      transactionsId,
      facilityId,
      ticket,
      subscriptionId: user.subscriptionId,
      token: user.token
    };
    await axios
      .post(ROUTES.GLOBAL_URL, data)
      .then(response => {
        Swal.fire("", `Now serving ticket number ${ticket}`, "success");
        dispatch(SetFacilities(response.data.facilities));
      })
      .catch(e => {
        Swal.fire("", "Connection error", "warning");
      });
  }

  function setProps(bookingId, name) {
    setName(name);
    setBookingId(bookingId);
  }

  return (
    <div className="pl-2">
      <div className="row">
        <div className="col-md-12 col-sm-12">
          {/* BEGIN PORTLET*/}
          <Card className="portlet light tasks-widget ">
            <CardTitle
              style={{ fontSize: 16, paddingBlock: 10, marginTop: 6 }}
              className="text-center"
            >
              Facility Bookings
            </CardTitle>
            <PaidModal name={name} bookingId={bookingId} />
            <div className="portlet-body">
              <div className="table-scrollable table-responsive">
                <table
                  id="residentsTable"
                  className="table-striped table-responsive table table-hover"
                >
                  <thead>
                    <tr>
                      <th colSpan="2">ID</th>
                      <th colSpan="4">Facility name</th>
                      <th colSpan="4">Facility capaciy</th>
                      <th colSpan="4">Client name</th>
                      <th colSpan="4">Client telephone</th>
                      <th colSpan="4">Facility location</th>
                      <th colSpan="4">Facility description</th>
                      <th colSpan="4">Created on</th>
                      <th colSpan="4">From</th>
                      <th colSpan="4">To</th>
                      <th colSpan="4">Booking date</th>
                      <th colSpan="4">Ticket No#</th>
                      <th colSpan="4">Payment Status</th>
                      <th colSpan="5">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data[0].bookings.slice(pageFrom, pageto).map(booking => {
                      let paid = (
                        <span className="badge badge-danger p-1">
                          Payment not received
                        </span>
                      );

                      let content = null;
                      if (booking.status === "1") {
                        paid = (
                          <span className="badge badge-success p-1">
                            Payment received
                          </span>
                        );

                        if (booking.booked === "1") {
                          //dialogue
                          content = (
                            <div className="btn-group btn-group-sm">
                              <button
                                data-toggle="modal"
                                data-target="#paidModal"
                                style={{ fontColor: "#fff", width: 70 }}
                                type="button"
                                name="Block"
                                onClick={() =>
                                  setProps(
                                    booking.booking_transaction_id,
                                    booking.bookee_name
                                  )
                                }
                                className="btn btn-info"
                              >
                                View
                              </button>

                              <button
                                type="button"
                                style={{ fontColor: "#fff", width: 70 }}
                                className="btn text-white btn-primary text-white"
                              >
                                Booked
                              </button>
                            </div>
                          );
                        } else {
                          content = (
                            <div className="btn-group btn-group-sm">
                              <button
                                data-toggle="modal"
                                data-target="#paidModal"
                                className="btn btn-danger"
                                onClick={() =>
                                  setProps(
                                    booking.booking_transaction_id,
                                    booking.bookee_name
                                  )
                                }
                              >
                                View
                              </button>

                              <button
                                type="button"
                                onClick={() =>
                                  bookUser(
                                    booking.booking_transaction_id,
                                    booking.facilityId,
                                    booking.ticket
                                  )
                                }
                                className="btn text-white btn-info text-white"
                              >
                                Book
                              </button>
                            </div>
                          );
                        }
                      }

                      return (
                        <tr
                          data-toggle="tooltip"
                          title={booking.facilityDescription}
                          key={booking.booking_transaction_id}
                        >
                          <td colSpan="2">{booking.booking_transaction_id}</td>
                          <td colSpan="4">{booking.facilityName}</td>
                          <td colSpan="4">{booking.capacity}</td>
                          <td colSpan="4">{booking.bookee_name}</td>
                          <td colSpan="4">{booking.phone}</td>
                          <td colSpan="4">{booking.location}</td>
                          <td colSpan="4">{booking.facilityDescription}</td>
                          <td colSpan="4">{booking.created_at}</td>
                          <td colSpan="4">{booking.from_time}</td>
                          <td colSpan="4">{booking.to_time}</td>
                          <td colSpan="4">{booking.s_date}</td>
                          <td colSpan="4">
                            <span className="badge badge-md badge-success p-1">
                              Ticket {booking.ticket}
                            </span>
                          </td>
                          <td colSpan="4">{paid}</td>
                          <td colSpan="4">{content}</td>
                          {/* <td colSpan="5">
                            <button className="btn text-sm btn-info btn-sm">
                              view more
                            </button>
                          </td> */}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
            <CardFooter
              style={{
                fontSize: 14,
                marginTop: 6,
                PaddingBottom: 30
              }}
            >
              <PagingItems
                setPagingNext={setPagingNext}
                setPagingPrev={setPagingPrev}
              />
            </CardFooter>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default React.memo(FacilityBookings);
