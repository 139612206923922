import React from "react";

const PagingItems = props => {
  return (
    <nav aria-label="Page navigation example">
      <ul className="pagination justify-content-end">
        <li className="page-item " onClick={props.setPagingPrev}>
          <a className="page-link" tabIndex="-1">
            Previous
          </a>
        </li>
        <li className="page-item">
          <a className="page-link " href="#">
            1
          </a>
        </li>
        <li className="page-item disabled">
          <a className="page-link" href="#">
            2
          </a>
        </li>
        <li className="page-item disabled">
          <a className="page-link" href="#">
            3
          </a>
        </li>
        <li className="page-item" onClick={props.setPagingNext}>
          <a className="page-link">Next</a>
        </li>
      </ul>
    </nav>
  );
};

export default PagingItems;
