const chooserReducer = (state = null, action) => {
  switch (action.type) {
    case "SUBSCRIBE":
      return (state = action.data);
    case "UNSUBSCRIBE":
      return (state = null);
    default:
      return state;
  }
};

export default chooserReducer;
