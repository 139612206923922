import React, { useEffect } from "react";
import {
  faUsers,
  faBuilding,
  faCodeBranch,
  faUserAlt
} from "@fortawesome/free-solid-svg-icons";
import Wrapper from "../Items/wrapper";
import HoverDashboardItem from "../Items/hoverableDashItem";
import { useSelector } from "react-redux";

const DashboardComponent = props => {
  const data = props.data;
  const guests = useSelector(state => state.visitorsReducer);
  const residents = useSelector(state => state.residentsReducer);
  const branches = useSelector(state => state.branchesReducer); 
   const facilities = useSelector(state => state.bookingReducer);

  useEffect(() => {}, [residents, branches, guests]);
  return (
    <Wrapper>
      <HoverDashboardItem
        to="/guests"
        count={guests.length}
        text="Guests"
        icon={faUsers}
      />
      <HoverDashboardItem
        to="/facilities"
        count={facilities.length}
        text="Facilities"
        icon={faBuilding}
      />
      <HoverDashboardItem
        to="/residents"
        count={residents.length}
        text="Residents"
        icon={faUserAlt}
      />
      <HoverDashboardItem
        to="/branches"
        count={branches.length}
        text=" Company Branche(s)"
        icon={faCodeBranch}
      />
    </Wrapper>
  );
};

export default DashboardComponent;
