import React, { useEffect, useState } from "react";
import { Card, CardTitle } from "reactstrap";
import ImageGallery from "react-image-gallery";
import axios from "axios";
import { useSelector } from "react-redux";
import * as ROUTES from "../constants";

const Gallery = ({ match }) => {
  //   /:guestId/:name/:badge/:purpose/:luggage"

  const [images, setImages] = useState([
    {
      original: "https://rms.changia.co.ke/appDev/Images/logo.png",
      thumbnail: "https://rms.changia.co.ke/appDev/Images/logo.png",
      description: "Residents Management System Portfolio Touch"
    }
  ]);
  const dir = useSelector(state => state.chooserReducer);

  useEffect(() => {
    renderImages();
  }, [match]);

  async function renderImages() {
    const data = {
      action: "getImages",
      visitorId: match.params.guestId,
      subscriptionId: dir.subscriptionId,
      token: dir.token
    };
    await axios.post(ROUTES.GUESTS_URL, data).then(response => {
      if (response.status === 200) {
        setImages(response.data.files);
      }
    });
  }

  return (
    <div className="container-fluid mt-2">
      <Card className="p-2">
        <CardTitle>
          <h6 className="text-center username mt-3">
            THE FOLLOWING IMAGES WERE ADDED TO-{match.params.name.toUpperCase()}
          </h6>
        </CardTitle>
        <Card className="portlet light tasks-widget p-1">
          <ImageGallery
            showBullets="true"
            thumbnailPosition="right"
            items={images}
          />
        </Card>
      </Card>
    </div>
  );
  //   return (
  //     <div className="container-fluid mt-2">
  //       <Card className="portlet light tasks-widget p-2">
  //         <div className="row">
  //           <div className="col-md-3 col-sm-3">
  //             <div className="thumbnail">
  //               <a href={`${baseUrl}Images/person_one.jpg`} target="_blank">
  //                 <img
  //                   src={`${baseUrl}Images/person_one.jpg`}
  //                   className="rounded img-responsive"
  //                   style={{ width: 304, height: 236 }}
  //                 />
  //                 <div className="caption">
  //                   <p>Lorem ipsum...</p>
  //                 </div>
  //               </a>
  //             </div>
  //           </div>
  //           <div className="col-md-3 col-sm-3">
  //             <div className="thumbnail">
  //               <a href={`${baseUrl}Images/person_one.jpg`} target="_blank">
  //                 <img
  //                   src={`${baseUrl}Images/person_one.jpg`}
  //                   alt="Nature"
  //                   className="rounded img-responsive"
  //                   style={{ width: 304, height: 236 }}
  //                 />
  //                 <div className="caption">
  //                   <p>Lorem ipsum...</p>
  //                 </div>
  //               </a>
  //             </div>
  //           </div>
  //           <div className="col-md-3 col-sm-3">
  //             <div className="thumbnail">
  //               <a href={`${baseUrl}Images/person_one.jpg`} target="_blank">
  //                 <img
  //                   src={`${baseUrl}Images/person_one.jpg`}
  //                   className="rounded img-responsive"
  //                   style={{ width: 304, height: 236 }}
  //                 />
  //                 <div className="caption">
  //                   <p>Lorem ipsum...</p>
  //                 </div>
  //               </a>
  //             </div>
  //           </div>
  //         </div>
  //       </Card>
  //     </div>
  //   );
};

export default Gallery;
