import React from "react";
import Wrapper from "../Items/wrapper";
import DashboardItem from "../Items/dashboardItem";
import {
  faClosedCaptioning,
  faTractor,
  faCheckCircle
} from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";
import ProviderRegistration from "./addServiceModal";

const ServicesDashboard = () => {
  const dir = useSelector(state => state.servicesReducer);
  const user = useSelector(state => state.chooserReducer);

  if (user.roleId === "1" || user.roleId === "4") {
    return (
      <React.Fragment>
        <Wrapper>
          <DashboardItem text="Providers" icon={faTractor} count={dir.length} />
          <DashboardItem
            text="Active Providers"
            icon={faCheckCircle}
            count={dir.filter(s => s.status === "1").length}
          />
          <DashboardItem
            text="Blocked Providers"
            icon={faClosedCaptioning}
            count={dir.filter(s => s.status !== "1").length}
          />

          <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
            <div className="guestButton">
              <button
                type="button"
                data-toggle="modal"
                data-target="#AddServiceModal"
                className="btn  btn-sm btn-primary"
                data-toggle="modal"
                data-target="#AddServiceModal"
              >
                Add Provider
              </button>
            </div>
          </div>

          <ProviderRegistration />
        </Wrapper>
      </React.Fragment>
    );
  } else {
    return (
      <Wrapper>
        <DashboardItem text="Providers" icon={faTractor} count={dir.length} />
      </Wrapper>
    );
  }
};

export default ServicesDashboard;
