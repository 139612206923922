import React, { useEffect, useState } from "react";
import { faSpinner, faLocationArrow } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import RightChatItem from "./right";
import LeftChatItem from "./left";
import { useSelector } from "react-redux";
import * as ROUTES from "../constants";
import $ from "jquery";
import { store } from "react-notifications-component";
import UIfx from "uifx";
import mp3File from "../../assets/point.mp3";

const Chat = ({ match }) => {
  const complaints = useSelector(state => state.complaintsReducer);
  const dir = useSelector(state => state.chooserReducer);
  const beep = new UIfx(mp3File, {
    volume: 1.0, // number between 0.0 ~ 1.0
    throttleMs: 100
  });
  const colors = ["6f42c1", "007bff", "e83e8c", "55C1E7"];
  const [threads, setThreads] = useState([]);
  const [message, setMessage] = useState("");
  const [icon, setIcon] = useState(faLocationArrow);
  const [canSpin, setSpin] = useState(false);

  useEffect(() => {
    setData();
  }, [complaints]);

  function setData() {
    const complaint = complaints.filter(
      c => c.complaintId === match.params.complaintId
    );
    setThreads(complaint[0].thread);
  }

  async function sendSms() {
    if (message.trim().length < 2) {
      alert("Invalid message");
      return;
    }
    setSpin(true);
    setIcon(faSpinner);
    if (canSpin) {
      alert("Sending an sms please wait");
      return;
    }
    const data = {
      action: "addThread",
      token: dir.token,
      subscriptionId: dir.subscriptionId,
      senderId:
        dir.roleId === "1" || dir.roleId === "4"
          ? dir.profile.companyId
          : dir.profile.userId,
      receiverId: match.params.senderId,
      message,
      senderName: dir.profile.username,
      complaintId: match.params.complaintId
    };

    await axios
      .post(ROUTES.GLOBAL_URL, data)
      .then(response => {
        setSpin(false);
        setIcon(faLocationArrow);
        if (response.status === 200) {
          setMessage("");

          const notification = {
            title: "Success",
            message: "Message sent successfully",
            type: "warning",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
              duration: 3000,
              onScreen: true
            }
          };
          beep.play();
          store.addNotification(notification);

          setThreads(response.data.thread);
        }
      })
      .catch(error => {});
  }
  return (
    <div className="container-fluid my-1 mx-4 p-3 bg-white rounded shadow">
      {/* <div className="my-1 p-3 bg-white rounded shadow-sm"> */}
      <h6 className="border-bottom border-gray pb-2 mb-0">
        Chat History by {match.params.name}
      </h6>

      <div className="panel-collapse " id="collapseOne">
        <div className="panel-body">
          <ul className="chat">
            {threads.map(thread => {
              var randomColor =
                colors[Math.floor(Math.random() * colors.length)];
              let chatItem = null;
              if (thread.receiverId === dir.profile.userId) {
                if (thread.receiverId === thread.activeMember) {
                  chatItem = (
                    <RightChatItem
                      key={thread.threadId}
                      randomColor={randomColor}
                      name={thread.senderName}
                      time={thread.sentOn}
                      message={thread.message}
                    />
                  );
                } else {
                  chatItem = (
                    <LeftChatItem
                      key={thread.threadId}
                      randomColor={randomColor}
                      name={thread.senderName}
                      time={thread.sentOn}
                      message={thread.message}
                    />
                  );
                }
              } else {
                if (thread.receiverId === thread.activeMember) {
                  chatItem = (
                    <LeftChatItem
                      key={thread.threadId}
                      randomColor={randomColor}
                      name={thread.senderName}
                      time={thread.sentOn}
                      message={thread.message}
                    />
                  );
                } else {
                  chatItem = (
                    <RightChatItem
                      key={thread.threadId}
                      randomColor={randomColor}
                      name={thread.senderName}
                      time={thread.sentOn}
                      message={thread.message}
                    />
                  );
                }
              }

              return chatItem;
            })}
          </ul>
        </div>
        <div className="card-footer mb-5 ">
          <div className="input-group">
            <textarea
              onKeyPress={event => {
                if (event.key === "Enter") {
                  sendSms();
                }
              }}
              name=""
              value={message}
              onChange={e => setMessage(e.target.value)}
              className="form-control type_msg p-2"
              placeholder="Type your message..."
            ></textarea>
            <div className="input-group-append mt-1" onClick={() => sendSms()}>
              <span className="input-group-text send_btn">
                <FontAwesomeIcon icon={icon} spin={canSpin} />
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(Chat);
