import React from "react";

const RightChatItem = props => {
  return (
    <li className="right clearfix py-3">
      <span className="chat-img pull-right float-right mr-3">
        <img
          src={`https://placehold.it/50/55C1E7/fff&text=${props.name.slice(
            0,
            2
          )}`}
          alt="User Avatar"
          className="rounded-circle"
        />
      </span>
      <div className="chat-body clearfix">
        <div className="header">
          <small className=" text-muted">
            <span className="glyphicon glyphicon-time"></span>
            {props.time}
          </small>
          <strong className="pull-right primary-font ml-2">{props.name}</strong>
        </div>
        <p>{props.message}</p>
      </div>
    </li>
  );
};

export default RightChatItem;
