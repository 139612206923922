import React, { useState, useEffect } from "react";
import PagingItems from "../Guests/pagingItems";
import { useSelector } from "react-redux";
import { Card, CardTitle, CardFooter, NavLink } from "reactstrap";
import { Link } from "react-router-dom";
import DashboardComponent from "./dashboard";
const BookingList = props => {
  let from = 0;
  let to = 10;
  const dir = useSelector(state => state.bookingReducer);

  useEffect(() => {}, [dir]);

  const [pageto, setPageTo] = useState(to);
  const [pageFrom, setPageFrom] = useState(from);

  function setPagingNext() {
    setPageTo(pageto + 10);
    setPageFrom(pageFrom + 10);
  }

  function setPagingPrev() {
    setPageTo(pageto - 10);
    setPageFrom(pageFrom - 10);
  }

  return (
    <div className="container-fluid">
      <DashboardComponent props={props.dir} />
      <div className="row">
        <div className="col-md-12 col-sm-12">
          {/* BEGIN PORTLET*/}
          <Card className="portlet light tasks-widget">
            <CardTitle
              style={{ fontSize: 16, paddingBlock: 10, marginTop: 6 }}
              className="text-center"
            >
              Facilities and Bookings
            </CardTitle>
            <div className="portlet-body">
              <div className="table-scrollable table-responsive">
                <table
                  id="residentsTable"
                  className="table-striped table-responsive table table-hover"
                >
                  <thead>
                    <tr>
                      <th colSpan="2">ID</th>
                      <th colSpan="4">Facility name</th>
                      <th colSpan="4">Description</th>
                      <th colSpan="4">Query phone</th>
                      <th colSpan="4">Facility capacity</th>
                      <th colSpan="4">Facility type</th>
                      <th colSpan="4">Status</th>
                      <th colSpan="4">Facility charge</th>
                      <th colSpan="4">Created on</th>
                      <th colSpan="4">Bookings</th>
                      <th colSpan="2">Paid Bookings</th>
                      <th colSpan="2">Unpaid Bookings</th>
                      <th colSpan="5">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {dir.slice(pageFrom, pageto).map(facility => {
                      let type = "Private";
                      if (facility.isPublic === "1") {
                        type = "Public";
                      }

                      let status = "Active";
                      if (facility.status === "0") {
                        status = "Blocked";
                      }

                      let buttons = null;

                      if (facility.status === "1") {
                        if (facility.isPublic === "1") {
                          buttons = (
                            <div className="btn-group btn-group-sm">
                              <button
                                type="button"
                                onClick={() =>
                                  props.changeStatus(facility.id, "0")
                                }
                                className="btn btn-danger"
                              >
                                Block
                              </button>
                              <button
                                type="button"
                                style={{ fontColor: "#fff", width: 70 }}
                                className="btn text-white btn-info text-white"
                              >
                                <NavLink
                                  style={{ padding: 0, fontColor: "#fff" }}
                                  tag={Link}
                                  to={`/bookings/${facility.id}`}
                                >
                                  View
                                </NavLink>
                              </button>
                            </div>
                          );
                        } else {
                          buttons = (
                            <div className="btn-group btn-group-sm">
                              <button
                                onClick={() =>
                                  props.changeStatus(facility.id, "0")
                                }
                                className="btn btn-sm btn-danger"
                              >
                                Block
                              </button>
                            </div>
                          );
                        }
                      } else {
                        if (facility.isPublic === "1") {
                          buttons = (
                            <div className="btn-group btn-group-sm">
                              <button
                                type="button"
                                onClick={() =>
                                  props.changeStatus(facility.id, "1")
                                }
                                className="btn btn-primary"
                              >
                                Unblock
                              </button>
                              <button
                                type="button"
                                style={{ fontColor: "#fff", width: 70 }}
                                className="btn text-white btn-info text-white"
                              >
                                <NavLink
                                  style={{ padding: 0, fontColor: "#fff" }}
                                  tag={Link}
                                  to={`/bookings/${facility.id}`}
                                >
                                  View
                                </NavLink>
                              </button>
                            </div>
                          );
                        } else {
                          buttons = (
                            <div className="btn-group btn-group-sm">
                              <button
                                onClick={() =>
                                  props.changeStatus(facility.id, "1")
                                }
                                className="btn btn-sm btn-primary"
                              >
                                Unblock
                              </button>
                            </div>
                          );
                        }
                      }

                      let bookingClass = "primary";
                      let paid = 0;
                      let unpaid = 0;
                      const total = facility.bookings.length;
                      if (total > 5) {
                        bookingClass = "success";
                      } else if (total > 15) {
                        bookingClass = "danger";
                      }

                      facility.bookings.forEach(booking => {
                        if (facility.isPublic) {
                          if (booking.status === "1") {
                            paid++;
                          } else {
                            unpaid++;
                          }
                        }
                      });

                      return (
                        <tr
                          data-toggle="tooltip"
                          title={facility.description}
                          key={facility.id}
                        >
                          <td colSpan="2">{facility.id}</td>
                          <td colSpan="4">{facility.name}</td>
                          <td colSpan="4">{facility.description}</td>
                          <td colSpan="4">{facility.mobile}</td>
                          <td colSpan="4">{facility.capacity}</td>
                          <td colSpan="4">{type}</td>
                          <td colSpan="4">{status}</td>
                          <td colSpan="4">{facility.pricing}</td>
                          <td colSpan="4">{facility.created_at}</td>
                          <td colSpan="4">
                            Bookings{"  "}
                            <span className={`badge badge-${bookingClass}`}>
                              {total}
                            </span>
                          </td>
                          <td colSpan="2">{paid}</td>
                          {/*Paid*/}
                          <td colSpan="2">{unpaid}</td>
                          {/*UnPaid*/}
                          <td colSpan="5">{buttons}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
            <CardFooter
              style={{
                fontSize: 14,
                marginTop: 6,
                PaddingBottom: 30
              }}
            >
              <PagingItems
                setPagingNext={setPagingNext}
                setPagingPrev={setPagingPrev}
              />
            </CardFooter>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default React.memo(BookingList);
