import React, { Component, useState, useEffect } from "react";
import ResidentDashboardComponent from "./dashboardComponent";
import ResidentsList from "./residentList";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

const Residents = props => {
  const residents = useSelector(state => state.residentsReducer);

  return (
    <React.Fragment>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12 col-sm-12">
            <ResidentDashboardComponent />
          </div>
        </div>
      </div>
      <ResidentsList
        subscriptionId={props.subscriptionId}
        token={props.token}
        data={residents}
        from={0}
        to={10}
      />
    </React.Fragment>
  );
};

Residents.propTypes = {
  residents: PropTypes.array.isRequired,
  token: PropTypes.string.isRequired,
  subscriptionId: PropTypes.string.isRequired
};

export default React.memo(Residents);
