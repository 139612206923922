import React, { Component } from "react";
import Chooser from "./choose";

class Companies extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const { companies, token } = this.props;
  }

  renderCompanies = () => {
    const { companies, token } = this.props;

    return <Chooser token={token} companies={companies} />;
  };
  render() {
    let contents = this.renderCompanies();

    return <React.Fragment>{contents}</React.Fragment>;
  }
}

export default Companies;
