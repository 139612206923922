import React, { useEffect, useState } from "react";
import ShimmerLoader from "../Shimmer/shimmer";
import BookingList from "./List";
import { useSelector, useDispatch } from "react-redux";
import { SetFacilities } from "../../actions";
import axios from "axios";
import Swal from "sweetalert2";
import * as ROUTES from "../constants";
const AdminBookings = () => {
  const dispatch = useDispatch();

  const dir = useSelector(state => state.chooserReducer);

  useEffect(() => {
    getFacilities();
  }, [dir]);

  const [view, setView] = useState(
    <ShimmerLoader message="Kindly try again later!" />
  );

  function changeStatus(facilityId, status) {
    let data = {
      action: "changeFacilityStatus",
      token: dir.token,
      facilityId,
      status,
      subscriptionId: dir.subscriptionId
    };

    axios
      .post(ROUTES.GLOBAL_URL, data)
      .then(response => {
        dispatch(SetFacilities(response.data.facilities));
        setView(<BookingList changeStatus={changeStatus} />);
      })
      .catch(() => {});
  }

  function getFacilities() {
    const data = {
      action: "getCompanyBookings",
      subscriptionId: dir.subscriptionId,
      token: dir.token
    };
    axios
      .post(ROUTES.GLOBAL_URL, data)
      .then(result => {
        dispatch(SetFacilities(result.data.facilities));
        setView(<BookingList changeStatus={changeStatus} />);
      })
      .catch(() => {
        Swal.fire("", "Connection timeout,try again later!", "warning");
      });
  }

  return <BookingList changeStatus={changeStatus} />;
};

export default React.memo(AdminBookings);
