import React from "react";

const ShimmerLoad = () => {
  return (
    <div className="my-1 ml-3 mr-3 p-3 bg-white rounded shadow-sm">
      <h6 className="border-bottom border-gray pb-2 mb-0">
        Please wait,loading
      </h6>
      <div className="page-container row">
        <div className="col-sm-6">
          <box className="shine"></box>
          <div className="linesDiv">
            <lines className="shine"></lines>
            <lines className="shine"></lines>
            <lines className="shine"></lines>
          </div>

          <photo className="shine"></photo>
          <photo className="shine"></photo>

          <br />

          <box className="shine"></box>

          <div className="linesDiv">
            <lines className="shine"></lines>
            <lines className="shine"></lines>
            <lines className="shine"></lines>
          </div>
        </div>
        <div className="col-sm-6">
          <box className="shine"></box>
          <div className="linesDiv">
            <lines className="shine"></lines>
            <lines className="shine"></lines>
            <lines className="shine"></lines>
          </div>

          <photo className="shine"></photo>
          <photo className="shine"></photo>

          <br />

          <box className="shine"></box>

          <div className="linesDiv">
            <lines className="shine"></lines>
            <lines className="shine"></lines>
            <lines className="shine"></lines>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShimmerLoad;
