import React, { useState, useEffect } from "react";
import ErrorBoundary from "../Errors/ErrorBoundary";
import ModalDialog from "../Modals/modal";
import Swal from "sweetalert2";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { chooseCompany, SetFacilities } from "../../actions";

import * as ROUTES from "../constants";

const FacilityModal = () => {
  const dir = useSelector(state => state.chooserReducer);
  const [name, setName] = useState("");
  const [pricing, setPrice] = useState("");
  const [mobile, setMobile] = useState("");
  const [description, setDescription] = useState("");
  const [capacity, setCapacity] = useState("");
  const [isPublic, setPublic] = useState(false);
  const [hidden, setHide] = useState(true);

  const dispatch = useDispatch();

  const clickRef = React.createRef();

  function addFacility(e) {
    e.preventDefault();
    const data = {
      action: "addFacility",
      name,
      description,
      isPublic: isPublic ? 1 : 0,
      capacity,
      mobile: isPublic ? mobile : "",
      pricing: isPublic ? pricing : "",
      token: dir.token,
      subscriptionId: dir.subscriptionId
    };
    Swal.fire({
      type: "warning",
      text: "Proceed to add this facility?",
      showLoaderOnConfirm: true,
      showCancelButton: true,
      preConfirm: async () => {
        await axios
          .post(ROUTES.GLOBAL_URL, data)
          .then(response => {
            if (response.data.status) {
              clickRef.current.click();
              dispatch(SetFacilities(response.data.facilities));
              Swal.close();
              setName("");
              setDescription("");
              setMobile("");
              setPrice("");

              Swal.fire("", "Facility added successfully!", "success");
            } else {
              Swal.fire("", response.data.message, "warning");
            }
          })
          .catch(() => {});
      }
    });
  }

  function setPublicCheck(e) {
    setPublic(e);

    if (isPublic) {
      setHide(true);
    } else {
      setHide(false);
    }
  }
  return (
    <ErrorBoundary>
      <ModalDialog>
        <div className="modal" id="addFacilityModal">
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h3 className="modal-title">Add a new facility</h3>
                {/* <button type="button" className="close" data-dismiss="modal">
                &times;
              </button> */}
              </div>
              <div className="modal-body">
                <form onSubmit={e => addFacility(e)} className="residentForm">
                  <div className="form-group">
                    <input
                      type="text"
                      value={name}
                      required
                      onChange={e => setName(e.target.value)}
                      className="form-control"
                      placeholder="Enter facility name (required)"
                    />
                  </div>

                  <div className="form-group">
                    <input
                      type="text"
                      value={description}
                      required
                      onChange={e => setDescription(e.target.value)}
                      className="form-control"
                      placeholder="Enter facility description (required)"
                    />
                  </div>

                  <div className="form-group">
                    <input
                      type="text"
                      value={capacity}
                      required
                      onChange={e => setCapacity(e.target.value)}
                      className="form-control"
                      placeholder="Enter facility capacity"
                    />
                  </div>

                  <div className="form-group">
                    <div className="form-inline">
                      <input
                        id="price"
                        hidden={hidden}
                        type="text"
                        value={pricing}
                        onChange={e => setPrice(e.target.value)}
                        className="form-control"
                        placeholder="Enter facility pricing"
                      />

                      <div hidden={hidden} className="form-group">
                        <input
                          id="mobile"
                          type="text"
                          value={mobile}
                          onChange={e => setMobile(e.target.value)}
                          className="form-control"
                          placeholder="Enter facility mobile"
                        />
                      </div>

                      <div className="custom-control pl-5 custom-checkbox">
                        <input
                          type="checkbox"
                          checked={isPublic}
                          onChange={e => setPublicCheck(e.target.checked)}
                          className="custom-control-input  float-right "
                          id="customCheck"
                          name="example1"
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="customCheck"
                        >
                          Public Facility
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="float-right mr-auto">
                    <button
                      type="submit"
                      className="btn btn-outline-primary mr-2"
                    >
                      Submit
                    </button>

                    <button
                      type="submit"
                      ref={clickRef}
                      data-dismiss="modal"
                      className="btn btn-outline-danger"
                    >
                      Close
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </ModalDialog>
    </ErrorBoundary>
  );
};

export default FacilityModal;
