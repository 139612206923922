import React from "react";
import Wrapper from "../Items/wrapper";
import { Card } from "reactstrap";
import { useHistory } from "react-router-dom";
const ShimmerError = props => {
  const history = useHistory();
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-12 col-sm-12">
          {/* BEGIN PORTLET*/}
          <Card className="portlet light tasks-widget ">
            <div className="p-5 text-center error-page-text">
              {props.message}
              <button
                onClick={() => history.goBack()}
                type="button"
                className="btn  btn-sm btn-warning text-white ml-4"
              >
                Go Back
              </button>
            </div>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default ShimmerError;
