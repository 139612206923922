const eventsReducer = (state = null, action) => {
  switch (action.type) {
    case "SETEVENTS":
      return (state = action.data);
    case "REMOVEEVENTS":
      return (state = null);
    default:
      return state;
  }
};

export default eventsReducer;
