const complaintsReducer = (state = null, action) => {
    switch (action.type) {
      case "SETCOMPLAINTS":
        return (state = action.data);
      case "REMOVECOMPLAINTS":
        return (state = null);
      default:
        return state;
    }
  };
  
  export default complaintsReducer;
  