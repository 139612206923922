import React, { useEffect } from "react";
import { Card, CardTitle, CardFooter } from "reactstrap";
import NotificationItem from "./notificationItem";
import { useSelector } from "react-redux";
import $ from "jquery";
import ModalAddEvent from "./addEvent";
import { useState } from "react";
import PagingItems from "../Guests/pagingItems";

const Events = props => {
  const dir = useSelector(state => state.chooserReducer);
  const events = useSelector(state => state.eventsReducer);
  const [copy, setCopy] = useState([]);
  const [pageFrom, setPageFrom] = useState(0);
  const [pageto, setPageTo] = useState(5);
  let admin = false;
  useEffect(() => {
    setCopy(events);
  }, [events]);

  if (dir.roleId === "1" || dir.roleId === "4") {
    admin = true;
  }
  function setPagingNext() {
    setPageTo(pageto + 5);
    setPageFrom(pageFrom + 5);
  }

  function setPagingPrev() {
    setPageTo(pageto - 5);
    setPageFrom(pageFrom - 5);
  }

  function recentCount() {
    let newDate = new Date();
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();

    let today = `${year}-${month < 10 ? `0${month}` : `${month}`}-${date}`;

    let upcoming = events.filter(
      e => Date.parse(e.eventDate) >= Date.parse(today)
    );
    return upcoming;
  }

  function filterForToday() {
    let eventsComing = recentCount();

    setCopy(eventsComing);
  }

  return (
    <div className="row mt-1">
      <div className="col-md-8 col-sm-8">
        {/* BEGIN PORTLET*/}
        <ModalAddEvent />
        <div className="my-1 p-3 bg-white rounded shadow-sm">
          <h6 className="border-bottom border-gray pb-2 mb-0">
            Upcoming events{" "}
            <span
              onClick={() => filterForToday()}
              className="badge badge-danger "
            >
              {recentCount().length}
            </span>
            {admin && (
              <button
                type="button"
                data-toggle="modal"
                data-target="#eventModal"
                className="btn btn-sm px-2 py-1  mb-1 btn-info float-right"
              >
                +
              </button>
            )}
            
          </h6>

          {copy.slice(pageFrom, pageto).map(event => {
            return (
              <NotificationItem
                key={event.eventId}
                eventType={event.eventType}
                describe={event.eventDescription}
                title={event.eventTitle}
                approvals={event.votedYes}
                rejects={event.votedNo}
                date={event.eventDate}
                eventId={event.eventId}
                posted_on={event.posted_on}
              />
            );
          })}
          <small className="d-block text-right mt-3">
            {/* <a href="#">All updates</a> */}
            <PagingItems
              setPagingNext={setPagingNext}
              setPagingPrev={setPagingPrev}
            />
          </small>
        </div>
      </div>
      <div className="col-md-4 col-sm-4">
        {/* BEGIN PORTLET*/}

        <div className="my-1 p-3 bg-white rounded shadow-sm">
          <h6 className="border-bottom border-gray pb-2 mb-0">
            Recent Notifications
          </h6>

          {dir.notifications.slice(0, 5).map(event => {
            return (
              <NotificationItem
                key={event.notificatiionId}
                eventType={0}
                describe={event.message}
                title={event.title}
                date={event.created_at}
                eventId={event.notificatiionId}
                posted_on={event.created_at}
              />
            );
          })}
          <small className="d-block text-right mt-3">
            <a href="#">All Notifications</a>
          </small>
        </div>
      </div>
    </div>
  );
};

export default Events;
