export const GUESTS_URL = "/api/engine/Verification/Main/Guests/";

export const LOGIN_URL = "/api/engine/Verification/Main/Login/";

export const SERVICES_URL = "/api/";

export const GLOBAL_URL = "/api/";

export const SEARCH_URL = "/api/engine/Verification/";

export const CHOOSE_URL = "/api/engine/Verification/Main/Choose/";

export const RESIDENTS_URL = "/api/engine/Verification/Main/Residents/";
