import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Login, setData } from "../../actions";
import loginImg from "../../assets/redisentapp-logo.png";
import axios from "axios";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";
import * as ROUTES from "../constants";
import "react-notifications-component/dist/theme.css";
import { store } from "react-notifications-component";
import UIfx from "uifx";
import mp3File from "../../assets/point.mp3";
import ErrorBoundary from "../Errors/ErrorBoundary";
require("../css/login.css");

const LoginView = () => {
  const history = useHistory();
  const config = {
    headers: { "Content-Type": "application/json" }
  };
  const [username, setName] = useState("");
  const [password, setPassword] = useState("");
  const beep = new UIfx(mp3File, {
    volume: 1, // number between 0.0 ~ 1.0
    throttleMs: 100
  });

  const dispatch = useDispatch();

  function handleSubmit(e) {
    e.preventDefault();

    Swal.queue([
      {
        confirmButtonText: "Yes i agree",
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#ff0000",
        html:
          "Do you agree to terms and conditions?<br/><i><a href='https://residents.co.ke/terms/' target='_blank'>Terms and conditions</a></i>",
        showLoaderOnConfirm: true,
        preConfirm: async () => {
          try {
            const userData = {
              action: "loginUser",
              username,
              password,
              token: 0
            };

            await axios
              .post(ROUTES.LOGIN_URL, userData, config)
              .then(response => {
                beep.play();
                const data = response.data;
                history.push("/");
                var { text } = "";
                if (data.status) {
                  text =
                    "You have logged in into the residence management system.";
                  Swal.fire("", text, "success");
                  dispatch(Login());
                  dispatch(setData(data));
                } else {
                  text = data.message;
                  Swal.fire("", text, "warning");
                }
              })
              .catch(() => {
                Swal.fire(
                  "",
                  "Connection not established,please try again!",
                  "error"
                );
              });
          } catch (e) {
            Swal.fire(
              "",
              "Connection not established,please try again!",
              "error"
            );
          }
        }
      }
    ]);
  }

  return (
    <ErrorBoundary>
      <React.Fragment>
        <form
          className="modal-content animate"
          onSubmit={e => handleSubmit(e)}
          crossOrigin="anonymous"
        >
          <div className="imgcontainer">
            <img src={loginImg} alt="rms" className="avatar" />
          </div>

          <div className="container">
            <input
              type="text"
              placeholder="Enter Username"
              value={username}
              required
              name="username"
              onChange={e => setName(e.target.value)}
            />
            <input
              type="password"
              value={password}
              placeholder="Enter Password"
              name="password"
              required
              onChange={e => setPassword(e.target.value)}
            />

            <button type="submit" className="loginBtn">
              Login
            </button>
          </div>

          <div className="container">
            <span className="psw">
              {/* Inquiries <a href="#">reach us today</a> */}
            </span>
          </div>
        </form>
      </React.Fragment>
    </ErrorBoundary>
  );
};

export default LoginView;
