import { combineReducers } from "redux";
import loginReducer from "./login";
import moduleReducer from "./module";
import chooserReducer from "./chooser";
import guestReducer from "./guest";
import bookingReducer from "./booking";
import servicesReducer from "./services";
import branchesReducer from "./branches";
import residentsReducer from "./residents";
import visitorsReducer from "./visitors";
import subscriptionsReducer from "./subscriptions";
import eventsReducer from "./events";
import complaintsReducer from "./complaint";
const systemReducers = combineReducers({
  loginReducer,
  moduleReducer,
  chooserReducer,
  guestReducer,
  bookingReducer,
  eventsReducer,
  subscriptionsReducer,
  servicesReducer,
  branchesReducer,
  residentsReducer,
  visitorsReducer,
  complaintsReducer
});

export default systemReducers;
