import React from "react";
import { useDispatch } from "react-redux";
import {
  deleteCompany,
  Logout,
  UnRegulate,
  removeData,
  RemoveFacilities,
  servicesRemover,
  residentsRemover,
  visitorsRemover
} from "../../actions/";
import {
  faSignOutAlt,
  faBookOpen,
  faStickyNote,
  faPhoneSquare,
  faBezierCurve
} from "@fortawesome/free-solid-svg-icons";
import SideWrap from "../Items/SideListWrapper";
import SideBarItem from "../Items/sidebarItem";

const StrangerItem = props => {
  const dispatch = useDispatch();

  function LogoutSystem() {
    dispatch(deleteCompany());
    dispatch(Logout());
    dispatch(UnRegulate());
    dispatch(removeData());
    dispatch(RemoveFacilities());
    dispatch(servicesRemover());
    dispatch(residentsRemover());
    dispatch(visitorsRemover());
  }

  return (
    <SideWrap>
      <SideBarItem
        handleClick={e => props.collectStats(e)}
        text="Companies"
        icon={faBezierCurve}
        to="/"
      />
      <SideBarItem
        handleClick={e => props.collectStats(e)}
        text="Bookings"
        icon={faBookOpen}
        to="/bookings"
      />
      <SideBarItem
        text="Notifications"
        handleClick={e => props.collectStats(e)}
        icon={faStickyNote}
        to="/notifications"
      />
      <SideBarItem
        text="Contact Us"
        handleClick={e => props.collectStats(e)}
        icon={faPhoneSquare}
        to="/"
      />
      <SideBarItem
        text="Logout"
        handleClick={() => LogoutSystem()}
        icon={faSignOutAlt}
        to="/"
      />
    </SideWrap>
  );
};

export default StrangerItem;
