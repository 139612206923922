const branchesReducer = (state = null, action) => {
  switch (action.type) {
    case "SETBRANCHES":
      return (state = action.data);
    case "REMOVEBRANCHES":
      return (state = null);
    default:
      return state;
  }
};

export default branchesReducer;
