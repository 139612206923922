import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { BrowserRouter as Router } from "react-router-dom";
import * as serviceWorker from "./serviceWorker";
import { createStore } from "redux";
import systemReducers from "./reducers";
import { Provider } from "react-redux";

// const persistedState = loadLocal();

const store = createStore(
  systemReducers,
  // persistedState,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

// function loadLocal() {
//   const serialisedData = localStorage.getItem("store");
//   return JSON.parse(serialisedData);
// }

// function saveToLocalStorage(store) {
//   const serialState = JSON.stringify(store);
//   localStorage.setItem("store", serialState);
// }

// store.subscribe(() => saveToLocalStorage(store.getState()));
const rootElement = document.getElementById("root");

ReactDOM.render(
  <Provider store={store}>
    <Router>
      <App />
    </Router>
  </Provider>,
  rootElement
);

if ("serviceWorker" in navigator) {
  navigator.serviceWorker
    .register("./firebase-messaging-sw.js")
    .then(function(registration) {
      console.log("Registration successful, scope is:", registration.scope);
    })
    .catch(function(err) {
      console.log("Service worker registration failed, error:", err);
    });
}

// serviceWorker.register();
