import React from "react";
import { useSelector } from "react-redux";
import BranchesDashboard from "./dashboard";
import Branches from "./branchList";
const BranchesComponent = () => {
  const dir = useSelector(state => state.chooserReducer);
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-12 col-sm-12">
          <BranchesDashboard />
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 col-sm-12">
          <Branches />
        </div>
      </div>
    </div>
  );
};

export default BranchesComponent;
