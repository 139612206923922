import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as ROUTES from "../constants";
import PagingItems from "../Guests/pagingItems";
import { CardFooter, CardBody, Card, CardHeader } from "reactstrap";
import Swal from "sweetalert2";
import { useEffect } from "react";
import ShimmerLoader from "../Shimmer/shimmer";
import { CopyToClipboard } from "react-copy-to-clipboard";

const SubscriptionsList = () => {
  const subscriptions = useSelector(state => state.subscriptionsReducer);
  const dispatch = useDispatch();

  useEffect(() => {}, [subscriptions]);

  const [pageto, setPageTo] = useState(10);
  const [pageFrom, setPageFrom] = useState(0);

  function setPagingNext() {
    setPageTo(pageto + 7);
    setPageFrom(pageFrom + 7);
  }

  function setPagingPrev() {
    setPageTo(pageto - 7);
    setPageFrom(pageFrom - 10);
  }

  function copyPayment() {
    Swal.fire("", "Copied successfully", "success");
  }
  return (
    <div className="portlet-body">
      <div className="table-scrollable table-responsive">
        <table className="table-striped table table-hover">
          <thead>
            <tr>
              <th colSpan="2">ID</th>
              <th colSpan="6">ACCOUNT</th>
              <th colSpan="6">M-PESA CODE</th>
              <th colSpan="6">PAID BY</th>
              <th colSpan="6">AMOUNT PAID</th>
              <th colSpan="6">EXPIRES ON</th>
              <th colSpan="6">TRANSACTION DATE</th>
              <th colSpan="6">Action</th>
            </tr>
          </thead>
          <tbody>
            {subscriptions.slice(pageFrom, pageto).map(subscription => {
              let buttons = (
                <CopyToClipboard
                  onCopy={() => copyPayment()}
                  text={`M-PESA CODE ${subscription.mpesaCode} 
                  \n M-PESA MOBILE ${subscription.paidBy}
                  \n PAYEE NAME ${subscription.senderName} for Acc RMS${subscription.subscriptionId} Ksh${subscription.amount_paid}\n on ${subscription.pay_date} `}
                >
                  <button className="btn btn-sm btn-info">Copy Details</button>
                </CopyToClipboard>
              );

              return (
                <tr key={subscription.transId}>
                  <td colSpan="2">{subscription.transId}</td>
                  <td colSpan="6">
                    {subscription.name}
                    <br />
                    <span className="badge badge-info p-1">
                      {subscription.roleName}
                    </span>
                    <br />
                    <span className="badge badge-success p-1 mt-1">
                      RMS{subscription.subscriptionId}
                    </span>
                  </td>
                  <td colSpan="6">{subscription.mpesaCode}</td>
                  <td colSpan="6">
                    {subscription.senderName}
                    <br />
                    {subscription.paidBy}
                  </td>
                  <td colSpan="6">Ksh{subscription.amount_paid}.00</td>
                  <td colSpan="6">{subscription.active_untill}</td>
                  <td colSpan="6">{subscription.pay_date}</td>
                  <td colSpan="6">{buttons}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      <CardFooter
        style={{
          fontSize: 14,
          marginTop: 5,
          PaddingBottom: 30
        }}
      >
        <PagingItems
          setPagingNext={setPagingNext}
          setPagingPrev={setPagingPrev}
        />
      </CardFooter>
    </div>
  );
};

export default SubscriptionsList;
