import React, { Component } from "react";
import ShimmerLoader from "../Shimmer/shimmer";
import { ReactDOM } from "react-dom";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { useState } from "react";
import NotificationItem from "../Events/notificationItem";
import PagingItems from "../Guests/pagingItems";
import Chat from "./chat";
import { NavLink } from "reactstrap";
import { Link } from "react-router-dom";
import ModalComplain from "./complainModal";

const Feedback = () => {
  const complaints = useSelector(state => state.complaintsReducer);
  const dir = useSelector(state => state.chooserReducer);
  const [copy, setCopy] = useState([]);
  let admin = false;

  const [pageFrom, setPageFrom] = useState(0);
  const [pageto, setPageTo] = useState(5);
  useEffect(() => {
    setCopy(complaints);
  }, [complaints]);

  if (dir.roleId === "1" || dir.roleId === "4") {
    admin = true;
  }
  function setPagingNext() {
    setPageTo(pageto + 5);
    setPageFrom(pageFrom + 5);
  }

  function setPagingPrev() {
    setPageTo(pageto - 5);
    setPageFrom(pageFrom - 5);
  }

  function recentCount() {
    let newDate = new Date();
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();

    let today = `${year}-${month < 10 ? `0${month}` : `${month}`}-${date}`;

    let upcoming = complaints.filter(
      e => Date.parse(e.sentOn) >= Date.parse(today)
    );
    return upcoming;
  }

  function filterForToday() {
    let complaintsToday = recentCount();

    setCopy(complaintsToday);
  }

  function openThread(complaintId) {
    // ReactDOM.render(<Chat />, document.getElementById("root"));
  }
  return (
    <div className="container-fluid my-1 mx-4 p-3 bg-white rounded shadow">
      {/* <div className="my-1 p-3 bg-white rounded shadow-sm"> */}
      <h6 className="border-bottom border-gray pb-2 mb-0">
        Recent Feedbacks{" "}
        <span onClick={() => filterForToday()} className="badge badge-danger ">
          {recentCount().length}
        </span>
        {!admin && (
          <button
            type="button"
            data-toggle="modal"
            data-target="#complaintModal"
            className="btn btn-sm px-2 py-1  mb-1 btn-info float-right"
          >
            +
          </button>
        )}
      </h6>
      <ModalComplain />
      {copy.slice(pageFrom, pageto).map(complaint => {
        return (
          <NavLink
            style={{ padding: 0, fontColor: "#fff" }}
            tag={Link}
            to={`/chat/${complaint.complaintId}/${complaint.senderId}/${complaint.senderName}`}
          >
            <NotificationItem
              onClick={() => openThread(complaint.complaintId)}
              className="complaint"
              key={complaint.complaintId}
              eventType={complaint.categoryName}
              describe={complaint.message}
              title={`${complaint.categoryName}- by ${complaint.senderName} on`}
              approvals="0"
              rejects="0"
              date={complaint.sentOn}
              eventId={complaint.complaintId}
              posted_on={complaint.sentOn}
            />
          </NavLink>
        );
      })}
      <small className="d-block text-right mt-3">
        {/* <a href="#">All updates</a> */}
        <PagingItems
          setPagingNext={setPagingNext}
          setPagingPrev={setPagingPrev}
        />
      </small>
      {/* </div> */}
    </div>
  );
};

export default React.memo(Feedback);
