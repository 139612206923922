const moduleReducer = (state = null, action) => {
  switch (action.type) {
    case "ADD":
      return (state = action.data);
    case "REMOVE":
      return (state = null);
    default:
      return state;
  }
};

export default moduleReducer;
