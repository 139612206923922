import React, { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBuilding,
  faPlusSquare,
  faTree,
  faWallet
} from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";
import Wrapper from "../Items/wrapper";
import FacilityModal from "./addFacility";
import DashboardItem from "../Items/dashboardItem";

import * as ROUTES from "../constants";

const DashboardComponent = props => {
  const dir = useSelector(state => state.bookingReducer);
  const pos = useSelector(state => state.chooserReducer);

  useEffect(() => {}, [props.props, dir]);
  return (
    <Wrapper>
      <DashboardItem text="Facilities" icon={faBuilding} count={dir.length} />
      <DashboardItem
        text=" Public Facilities"
        icon={faTree}
        count={dir.filter(f => f.isPublic === "1").length}
      />
      {/* <DashboardItem
        text=" Private Facilities"
        icon={faTree}
        count={dir.filter(f => f.isPublic === "0").length}
      />{" "} */}
      <DashboardItem
        text="Wallet Balance"
        icon={faWallet}
        count={pos.profile.balance}
      />
      <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
        <div className="guestButton">
          <button
            type="button"
            data-toggle="modal"
            data-target="#addFacilityModal"
            className="btn  btn-sm btn-primary"
          >
            <FontAwesomeIcon
              style={{
                color: "#ffffff",
                fontSize: 18,
                padding: 3
              }}
              icon={faPlusSquare}
            />{" "}
            Facility
          </button>
        </div>
      </div>
      <FacilityModal />
    </Wrapper>
  );
};

export default DashboardComponent;
