import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle, faBuilding } from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import {
  chooseCompany,
  servicesSetter,
  setBranches,
  residentsSetter,
  visitorsSetter,
  SetFacilities,
  setTransactions,
  setEvents,
  setComplaints
} from "../../actions";
import * as ROUTES from "../constants";
import { messaging } from "../../init-fcm";
import { store } from "react-notifications-component";
import UIfx from "uifx";
import mp3File from "../../assets/point.mp3";
import ErrorBoundary from "../Errors/ErrorBoundary";

const Chooser = props => {
  const dispatch = useDispatch();
  const [dir, setDir] = useState({});
  const [subscriptionId, setSubscription] = useState("");
  const [token, setToken] = useState("");
  const beep = new UIfx(mp3File, {
    volume: 1.0, // number between 0.0 ~ 1.0
    throttleMs: 100
  });
  async function setFirebase(subscriptionId, token) {
    messaging
      .requestPermission()
      .then(async function() {
        const fcmToken = await messaging.getToken();
        //send to server

        const data = {
          action: "updateToken",
          fcmToken,
          token,
          subscriptionId
        };

        await axios.post(ROUTES.GLOBAL_URL, data).then(res => {
          console.log(res.status, "FCM");
        });
      })
      .catch(function(err) {
        const notification = {
          title: "Notifications Permission",
          message: "You will not be able to get notification.",
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 3000,
            onScreen: true
          }
        };
        beep.play();
        store.addNotification(notification);
      });
  }

  const connection_error = {
    title: "Connection",
    message: "No internet connection",
    type: "danger",
    insert: "top",
    container: "top-right",
    animationIn: ["animated", "fadeIn"],
    animationOut: ["animated", "fadeOut"],
    dismiss: {
      duration: 3000,
      onScreen: true
    }
  };

  const companySelector = (
    subscriptionId,
    roleId,
    companyId,
    branchId,
    companyName
  ) => {
    Swal.queue([
      {
        confirmButtonText: "Proceed",
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        text: `Proceed to select company ${companyName}`,
        showLoaderOnConfirm: true,
        preConfirm: async () => {
          try {
            const postData = {
              action: "companyChosen",
              subscriptionId,
              roleId,
              companyId,
              branchId,
              token: props.token
            };
            await axios
              .post(ROUTES.CHOOSE_URL, postData)
              .then(response => {
                const res = response.data;

                if (res.status) {
                  setFirebase(res.profile.subscriptionId, res.token);
                  dispatch(servicesSetter(res.services));
                  dispatch(visitorsSetter(res.effectVisitor));
                  dispatch(residentsSetter(res.residents));
                  dispatch(setBranches(res.branches));
                  dispatch(setTransactions(res.transactions));
                  dispatch(setEvents(res.events));
                  dispatch(setComplaints(res.complaints));
                  if (res.roleId === "4" || res.roleId === "1") {
                    dispatch(SetFacilities(res.facilities));
                  } else {
                    dispatch(SetFacilities([]));
                  }
                  dispatch(chooseCompany(res));
                } else {
                  const res = response.data;

                  const notification = {
                    title: "Selection error",
                    message: res,
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                      duration: 3000,
                      onScreen: true
                    }
                  };
                  beep.play();
                  store.addNotification(notification);
                }
              })
              .catch(e => {
                beep.play();
                store.addNotification(connection_error);
              });
          } catch (e) {
            beep.play();
            store.addNotification(connection_error);
          }
        }
      }
    ]);
  };
  console.log("Here");

  return (
    <ErrorBoundary>
      <div className="container-fluid">
        <div className="row margin-top-5">
          {props.companies.map(company => {
            let hidden = false;
            if (company.systemStatus !== 1) {
              hidden = true;
            }
            return (
              <div
                onClick={() =>
                  companySelector(
                    company.subscriptionId,
                    company.roleId,
                    company.companyId,
                    company.branchId,
                    company.companyName
                  )
                }
                key={company.subscriptionId}
                className="col-lg-3 col-md-3 col-sm-6 col-xs-12"
              >
                <div className="dashboard-stat2">
                  <div className="display">
                    <div className="number">
                      <h4 className="font-green-sharp">
                        {company.subscriptionId}
                        <i hidden={hidden}>
                          <FontAwesomeIcon
                            style={{ color: "#ff0000", fontSize: 13 }}
                            icon={faCircle}
                          />
                        </i>
                      </h4>
                      <small>
                        <b className="Link">
                          COMPANY :
                          <strong className="text-warning">
                            {" "}
                            {company.companyName}
                          </strong>
                          <br />
                          ROLE : {company.roleName}
                        </b>
                      </small>
                    </div>
                    <div className="icon">
                      <i className="fa fa-cubes font-blue-sharp">
                        <FontAwesomeIcon
                          style={{
                            color: "#5C9BD1",
                            fontSize: 30
                          }}
                          icon={faBuilding}
                        />
                      </i>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </ErrorBoundary>
  );
};

export default Chooser;
