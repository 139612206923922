
const guestReducer = (state = false, action) => {
    switch (action.type) {
      case "REGULATE":
        return (state = action.data);
      case "UNREGULATE":
        return (state = null);
      default:
        return state;
    }
  };
  
  export default guestReducer;
  