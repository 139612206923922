import React from "react";

const LeftChatItem = props => {
  return (
    <li className="left clearfix d-flex py-3">
      <span className="chat-img">
        <img
          src={`https://placehold.it/50/6f42c1/fff&text=${props.name.slice(
            0,
            1
          )}`}
          alt="User Avatar"
          className="rounded-circle"
        />
      </span>
      <div className="chat-body ml-3 my-1">
        <div className="header">
          <strong className="primary-font">{props.name}</strong>{" "}
          <small className="pull-right text-muted">
            <span className="glyphicon glyphicon-time"></span>
            {props.time}
          </small>
        </div>
        <p>{props.message}</p>
      </div>
    </li>
  );
};

export default LeftChatItem;
