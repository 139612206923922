import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { faUsers, faUserAlt, faChild } from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Swal from "sweetalert2";
import axios from "axios";
import GuestsComponent from "../Guests/residentsGuests";
import ShimmerLoader from "../Shimmer/shimmer";
import { useHistory } from "react-router-dom";

import * as ROUTES from "../constants";

const ResidentRequest = ({ match }) => {
  const [info, setInfo] = useState();
  const state = useSelector(state => state.chooserReducer);
  const history = useHistory();

  useEffect(() => {
    getResidentInfo();
  }, []);

  function getResidentInfo() {
    const userData = {
      action: "residentInfo",
      memberId: match.params.residentId,
      subscriptionId: state.subscriptionId,
      token: state.token
    };

    axios
      .post(ROUTES.RESIDENTS_URL, userData)
      .then(response => {
        const data = response.data;
        setInfo(data);
      })
      .catch(() => {
        history.push("/");
        Swal.fire({
          type: "error",
          text: "No internet connection"
        });
      });
  }
  if (info === undefined) {
    return (
      <ShimmerLoader message="No residents data was found or there was a connection timeout,kindly try again!" />
    );
  } else {
    return (
      <React.Fragment>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 col-sm-12">
              <div className="row margin-top-5">
                <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                  <div className="dashboard-stat2">
                    <div className="display">
                      <div className="number">
                        <h4 className="font-green-sharp">
                          <span className="badge  badge-primary">
                            {info.property.length}
                          </span>
                        </h4>
                        <small>Total Properties</small>
                      </div>
                      <div className="icon">
                        <i className="fa fa-cubes font-blue-sharp">
                          <FontAwesomeIcon
                            style={{
                              color: "#5C9BD1",
                              fontSize: 30
                            }}
                            icon={faUsers}
                          />
                        </i>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                  <div className="dashboard-stat2">
                    <div className="display">
                      <div className="number">
                        <h4
                          style={{ color: "#1b8f04" }}
                          className="font-green-sharp"
                        >
                          <span className="badge  badge-success">
                            {info.family.length}{" "}
                          </span>
                        </h4>
                        <small>Associates</small>
                      </div>
                      <div className="icon">
                        <i className="fa fa-building font-blue-sharp">
                          <FontAwesomeIcon
                            style={{
                              color: "#5C9BD1",
                              fontSize: 30
                            }}
                            icon={faChild}
                          />
                        </i>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                  <div className="dashboard-stat2">
                    <div className="display">
                      <div className="number">
                        <h4 className="font-green-sharp">
                          <span className="badge  badge-danger">
                            {info.effectVisitor.length}
                          </span>
                        </h4>
                        <small>Guests</small>
                      </div>
                      <div className="icon">
                        <i className="fa fa-building-o font-blue-sharp">
                          <FontAwesomeIcon
                            style={{
                              color: "#5C9BD1",
                              fontSize: 30
                            }}
                            icon={faUserAlt}
                          />
                        </i>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12"></div>
              </div>
            </div>
          </div>
        </div>
        <GuestsComponent
          recenttest={`${match.params.name}'s Guests`}
          data={info.effectVisitor}
          from={0}
          to={10}
        />
      </React.Fragment>
    );
  }
};

export default React.memo(ResidentRequest);
