import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { NavLink } from "reactstrap";
import { Link } from "react-router-dom";
const SideBarItem = props => {
  return (
    <li className="nav-gation">
      <NavLink onClick={() => props.handleClick()} tag={Link} to={props.to}>
        <i className="fa-pull-right">
          <FontAwesomeIcon icon={props.icon} />
        </i>
        <span className="title">{props.text}</span>
      </NavLink>
    </li>
  );
};

export default SideBarItem;
