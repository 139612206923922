import React, { useEffect, useState } from "react";
import ErrorBoundary from "../Errors/ErrorBoundary";
import ModalDialog from "../Modals/modal";
import Swal from "sweetalert2";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import {
  UnRegulate,
  visitorsSetter,
  setEvents,
  setComplaints
} from "../../actions";
import Search from "../Search/search";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as ROUTES from "../constants";
import $ from "jquery";
const ModalComplain = () => {
  const dataState = useSelector(state => state.chooserReducer);
  const [category, setComplainType] = useState("0");
  const [message, setMessage] = useState("");
  const dispatch = useDispatch();
  const [hidden, spinHide] = useState(true);
  const [disabled, setDisabled] = useState(false);
  const spinner = (
    <FontAwesomeIcon
      className="mr-2 text-success"
      style={{
        fontSize: 20
      }}
      spin
      hidden={hidden}
      icon={faSpinner}
    />
  );

  function clearFields(e) {
    e.preventDefault();
    setMessage("");
    setComplainType("0");
  }

  const clickRef = React.createRef();

  async function sendComplain(e) {
    e.preventDefault();
    spinHide(false);
    setDisabled(true);
    const data = {
      action: "addComplaint",
      subscriptionId: dataState.subscriptionId,
      message,
      category,
      senderName: dataState.profile.username,
      token: dataState.token
    };

    await axios.post(ROUTES.GLOBAL_URL, data).then(response => {
      if (response.status === 200) {
        spinHide(true);
        setDisabled(false);
        setMessage("");
        setComplainType("0");
        dispatch(setComplaints(response.data.complaints));
        $("#complaintModal").modal("hide");
        Swal.fire("", "Posted successfully", "success");
      } else {
        spinHide(true);
        setDisabled(false);
        Swal.fire("", "Connection problem", "success");
      }
    });
  }

  return (
    <ErrorBoundary>
      <ModalDialog>
        <div className="modal" id="complaintModal">
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h3 className="modal-title">New Feedback</h3>
                {/* <button type="button" className="close" data-dismiss="modal">
                &times;
              </button> */}
              </div>
              <div className="modal-body">
                <form onSubmit={e => sendComplain(e)} className="residentForm">
                  <div className="form-group">
                    <select
                      value={category}
                      onChange={e => setComplainType(e.target.value)}
                      onLoad={e => setComplainType(e.target.value)}
                      className="form-control mb-3"
                      required
                    >
                      {dataState.complaintCategories.map(opt => {
                        return (
                          <option value={opt.id} key={opt.id}>
                            {opt.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      value={message}
                      required
                      onChange={e => setMessage(e.target.value)}
                      className="form-control"
                      placeholder="Enter your feedback"
                    />
                  </div>

                  <button
                    ref={clickRef}
                    onClick={e => clearFields(e)}
                    className="btn btn-outline-info"
                  >
                    Clear
                  </button>
                  <div className="float-right mr-auto">
                    <button
                      disabled={disabled}
                      className="btn btn-outline-primary mr-2"
                    >
                      {spinner}
                      Submit
                    </button>

                    <button
                      type="submit"
                      ref={clickRef}
                      data-dismiss="modal"
                      className="btn btn-outline-danger"
                    >
                      Close
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </ModalDialog>
    </ErrorBoundary>
  );
};

export default ModalComplain;
